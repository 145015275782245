@import "bootstrap-variables";

/* Common styles ====================================== */
.m-l--125 {
    margin-left: -125px;
}

.m-t--125 {
    margin-top: -125px;
}

.m-r--125 {
    margin-right: -125px;
}

.m-b--125 {
    margin-bottom: -125px;
}

.m-l--120 {
    margin-left: -120px;
}

.m-t--120 {
    margin-top: -120px;
}

.m-r--120 {
    margin-right: -120px;
}

.m-b--120 {
    margin-bottom: -120px;
}

.m-l--115 {
    margin-left: -115px;
}

.m-t--115 {
    margin-top: -115px;
}

.m-r--115 {
    margin-right: -115px;
}

.m-b--115 {
    margin-bottom: -115px;
}

.m-l--110 {
    margin-left: -110px;
}

.m-t--110 {
    margin-top: -110px;
}

.m-r--110 {
    margin-right: -110px;
}

.m-b--110 {
    margin-bottom: -110px;
}

.m-l--105 {
    margin-left: -105px;
}

.m-t--105 {
    margin-top: -105px;
}

.m-r--105 {
    margin-right: -105px;
}

.m-b--105 {
    margin-bottom: -105px;
}

.m-l--100 {
    margin-left: -100px;
}

.m-t--100 {
    margin-top: -100px;
}

.m-r--100 {
    margin-right: -100px;
}

.m-b--100 {
    margin-bottom: -100px;
}

.m-l--95 {
    margin-left: -95px;
}

.m-t--95 {
    margin-top: -95px;
}

.m-r--95 {
    margin-right: -95px;
}

.m-b--95 {
    margin-bottom: -95px;
}

.m-l--90 {
    margin-left: -90px;
}

.m-t--90 {
    margin-top: -90px;
}

.m-r--90 {
    margin-right: -90px;
}

.m-b--90 {
    margin-bottom: -90px;
}

.m-l--85 {
    margin-left: -85px;
}

.m-t--85 {
    margin-top: -85px;
}

.m-r--85 {
    margin-right: -85px;
}

.m-b--85 {
    margin-bottom: -85px;
}

.m-l--80 {
    margin-left: -80px;
}

.m-t--80 {
    margin-top: -80px;
}

.m-r--80 {
    margin-right: -80px;
}

.m-b--80 {
    margin-bottom: -80px;
}

.m-l--75 {
    margin-left: -75px;
}

.m-t--75 {
    margin-top: -75px;
}

.m-r--75 {
    margin-right: -75px;
}

.m-b--75 {
    margin-bottom: -75px;
}

.m-l--70 {
    margin-left: -70px;
}

.m-t--70 {
    margin-top: -70px;
}

.m-r--70 {
    margin-right: -70px;
}

.m-b--70 {
    margin-bottom: -70px;
}

.m-l--65 {
    margin-left: -65px;
}

.m-t--65 {
    margin-top: -65px;
}

.m-r--65 {
    margin-right: -65px;
}

.m-b--65 {
    margin-bottom: -65px;
}

.m-l--60 {
    margin-left: -60px;
}

.m-t--60 {
    margin-top: -60px;
}

.m-r--60 {
    margin-right: -60px;
}

.m-b--60 {
    margin-bottom: -60px;
}

.m-l--55 {
    margin-left: -55px;
}

.m-t--55 {
    margin-top: -55px;
}

.m-r--55 {
    margin-right: -55px;
}

.m-b--55 {
    margin-bottom: -55px;
}

.m-l--50 {
    margin-left: -50px;
}

.m-t--50 {
    margin-top: -50px;
}

.m-r--50 {
    margin-right: -50px;
}

.m-b--50 {
    margin-bottom: -50px;
}

.m-l--45 {
    margin-left: -45px;
}

.m-t--45 {
    margin-top: -45px;
}

.m-r--45 {
    margin-right: -45px;
}

.m-b--45 {
    margin-bottom: -45px;
}

.m-l--40 {
    margin-left: -40px;
}

.m-t--40 {
    margin-top: -40px;
}

.m-r--40 {
    margin-right: -40px;
}

.m-b--40 {
    margin-bottom: -40px;
}

.m-l--35 {
    margin-left: -35px;
}

.m-t--35 {
    margin-top: -35px;
}

.m-r--35 {
    margin-right: -35px;
}

.m-b--35 {
    margin-bottom: -35px;
}

.m-l--30 {
    margin-left: -30px;
}

.m-t--30 {
    margin-top: -30px;
}

.m-r--30 {
    margin-right: -30px;
}

.m-b--30 {
    margin-bottom: -30px;
}

.m-l--25 {
    margin-left: -25px;
}

.m-t--25 {
    margin-top: -25px;
}

.m-r--25 {
    margin-right: -25px;
}

.m-b--25 {
    margin-bottom: -25px;
}

.m-l--20 {
    margin-left: -20px;
}

.m-t--20 {
    margin-top: -20px;
}

.m-r--20 {
    margin-right: -20px;
}

.m-b--20 {
    margin-bottom: -20px;
}

.m-l--15 {
    margin-left: -15px;
}

.m-t--15 {
    margin-top: -15px;
}

.m-r--15 {
    margin-right: -15px;
}

.m-b--15 {
    margin-bottom: -15px;
}

.m-l--10 {
    margin-left: -10px;
}

.m-t--10 {
    margin-top: -10px;
}

.m-r--10 {
    margin-right: -10px;
}

.m-b--10 {
    margin-bottom: -10px;
}

.m-l--5 {
    margin-left: -5px;
}

.m-t--5 {
    margin-top: -5px;
}

.m-r--5 {
    margin-right: -5px;
}

.m-b--5 {
    margin-bottom: -5px;
}

.m-l-0 {
    margin-left: 0px;
}

.m-t-0 {
    margin-top: 0px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-b-0 {
    margin-bottom: 0px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l--20 {
    margin-left: -20px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-l-25 {
    margin-left: 25px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-r-25 {
    margin-right: 25px;
}

.m-b-25 {
    margin-bottom: 25px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-l-35 {
    margin-left: 35px;
}

.m-t-35 {
    margin-top: 35px;
}

.m-r-35 {
    margin-right: 35px;
}

.m-b-35 {
    margin-bottom: 35px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-l-45 {
    margin-left: 45px;
}

.m-t-45 {
    margin-top: 45px;
}

.m-r-45 {
    margin-right: 45px;
}

.m-b-45 {
    margin-bottom: 45px;
}

.m-l-50 {
    margin-left: 50px;
}

.m-t-50 {
    margin-top: 50px;
}

.m-r-50 {
    margin-right: 50px;
}

.m-b-50 {
    margin-bottom: 50px;
}

.m-l-55 {
    margin-left: 55px;
}

.m-t-55 {
    margin-top: 55px;
}

.m-r-55 {
    margin-right: 55px;
}

.m-b-55 {
    margin-bottom: 55px;
}

.m-l-60 {
    margin-left: 60px;
}

.m-t-60 {
    margin-top: 60px;
}

.m-r-60 {
    margin-right: 60px;
}

.m-b-60 {
    margin-bottom: 60px;
}

.m-l-65 {
    margin-left: 65px;
}

.m-t-65 {
    margin-top: 65px;
}

.m-r-65 {
    margin-right: 65px;
}

.m-b-65 {
    margin-bottom: 65px;
}

.m-l-70 {
    margin-left: 70px;
}

.m-t-70 {
    margin-top: 70px;
}

.m-r-70 {
    margin-right: 70px;
}

.m-b-70 {
    margin-bottom: 70px;
}

.m-l-75 {
    margin-left: 75px;
}

.m-t-75 {
    margin-top: 75px;
}

.m-r-75 {
    margin-right: 75px;
}

.m-b-75 {
    margin-bottom: 75px;
}

.m-l-80 {
    margin-left: 80px;
}

.m-t-80 {
    margin-top: 80px;
}

.m-r-80 {
    margin-right: 80px;
}

.m-b-80 {
    margin-bottom: 80px;
}

.m-l-85 {
    margin-left: 85px;
}

.m-t-85 {
    margin-top: 85px;
}

.m-r-85 {
    margin-right: 85px;
}

.m-b-85 {
    margin-bottom: 85px;
}

.m-l-90 {
    margin-left: 90px;
}

.m-t-90 {
    margin-top: 90px;
}

.m-r-90 {
    margin-right: 90px;
}

.m-b-90 {
    margin-bottom: 90px;
}

.m-l-95 {
    margin-left: 95px;
}

.m-t-95 {
    margin-top: 95px;
}

.m-r-95 {
    margin-right: 95px;
}

.m-b-95 {
    margin-bottom: 95px;
}

.m-l-100 {
    margin-left: 100px;
}

.m-t-100 {
    margin-top: 100px;
}

.m-r-100 {
    margin-right: 100px;
}

.m-b-100 {
    margin-bottom: 100px;
}

.m-l-105 {
    margin-left: 105px;
}

.m-t-105 {
    margin-top: 105px;
}

.m-r-105 {
    margin-right: 105px;
}

.m-b-105 {
    margin-bottom: 105px;
}

.m-l-110 {
    margin-left: 110px;
}

.m-t-110 {
    margin-top: 110px;
}

.m-r-110 {
    margin-right: 110px;
}

.m-b-110 {
    margin-bottom: 110px;
}

.m-l-115 {
    margin-left: 115px;
}

.m-t-115 {
    margin-top: 115px;
}

.m-r-115 {
    margin-right: 115px;
}

.m-b-115 {
    margin-bottom: 115px;
}

.m-l-120 {
    margin-left: 120px;
}

.m-t-120 {
    margin-top: 120px;
}

.m-r-120 {
    margin-right: 120px;
}

.m-b-120 {
    margin-bottom: 120px;
}

.m-l-125 {
    margin-left: 125px;
}

.m-t-125 {
    margin-top: 125px;
}

.m-r-125 {
    margin-right: 125px;
}

.m-b-125 {
    margin-bottom: 125px;
}

.margin-0 {
    margin: 0;
}

.p-l-0 {
    padding-left: 0px;
}

.p-t-0 {
    padding-top: 0px;
}

.p-r-0 {
    padding-right: 0px;
}

.p-b-0 {
    padding-bottom: 0px !important;
}

.p-l-5 {
    padding-left: 5px;
}

.p-t-5 {
    padding-top: 5px;
}

.p-r-5 {
    padding-right: 5px;
}

.p-b-5 {
    padding-bottom: 5px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.p-l-15 {
    padding-left: 15px !important;
}

.p-t-15 {
    padding-top: 15px;
}

.p-r-15 {
    padding-right: 15px;
}

.p-b-15 {
    padding-bottom: 15px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-t-20 {
    padding-top: 20px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-b-20 {
    padding-bottom: 20px;
}

.p-l-25 {
    padding-left: 25px;
}

.p-t-25 {
    padding-top: 25px;
}

.p-r-25 {
    padding-right: 25px;
}

.p-b-25 {
    padding-bottom: 25px;
}

.p-l-30 {
    padding-left: 30px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-r-30 {
    padding-right: 30px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-l-35 {
    padding-left: 35px;
}

.p-t-35 {
    padding-top: 35px;
}

.p-r-35 {
    padding-right: 35px;
}

.p-b-35 {
    padding-bottom: 35px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-t-40 {
    padding-top: 40px;
}

.p-r-40 {
    padding-right: 40px;
}

.p-b-40 {
    padding-bottom: 40px;
}

.p-l-45 {
    padding-left: 45px;
}

.p-t-45 {
    padding-top: 45px;
}

.p-r-45 {
    padding-right: 45px;
}

.p-b-45 {
    padding-bottom: 45px;
}

.p-l-50 {
    padding-left: 50px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-b-50 {
    padding-bottom: 50px;
}

.p-l-55 {
    padding-left: 55px;
}

.p-t-55 {
    padding-top: 55px;
}

.p-r-55 {
    padding-right: 55px;
}

.p-b-55 {
    padding-bottom: 55px;
}

.p-l-60 {
    padding-left: 60px;
}

.p-t-60 {
    padding-top: 60px;
}

.p-r-60 {
    padding-right: 60px;
}

.p-b-60 {
    padding-bottom: 60px;
}

.p-l-65 {
    padding-left: 65px;
}

.p-t-65 {
    padding-top: 65px;
}

.p-r-65 {
    padding-right: 65px;
}

.p-b-65 {
    padding-bottom: 65px;
}

.p-l-70 {
    padding-left: 70px;
}

.p-t-70 {
    padding-top: 70px;
}

.p-r-70 {
    padding-right: 70px;
}

.p-b-70 {
    padding-bottom: 70px;
}

.p-l-75 {
    padding-left: 75px;
}

.p-t-75 {
    padding-top: 75px;
}

.p-r-75 {
    padding-right: 75px;
}

.p-b-75 {
    padding-bottom: 75px;
}

.p-l-80 {
    padding-left: 80px;
}

.p-t-80 {
    padding-top: 80px;
}

.p-r-80 {
    padding-right: 80px;
}

.p-b-80 {
    padding-bottom: 80px;
}

.p-l-85 {
    padding-left: 85px;
}

.p-t-85 {
    padding-top: 85px;
}

.p-r-85 {
    padding-right: 85px;
}

.p-b-85 {
    padding-bottom: 85px;
}

.p-l-90 {
    padding-left: 90px;
}

.p-t-90 {
    padding-top: 90px;
}

.p-r-90 {
    padding-right: 90px;
}

.p-b-90 {
    padding-bottom: 90px;
}

.p-l-95 {
    padding-left: 95px;
}

.p-t-95 {
    padding-top: 95px;
}

.p-r-95 {
    padding-right: 95px;
}

.p-b-95 {
    padding-bottom: 95px;
}

.p-l-100 {
    padding-left: 100px;
}

.p-t-100 {
    padding-top: 100px;
}

.p-r-100 {
    padding-right: 100px;
}

.p-b-100 {
    padding-bottom: 100px;
}

.p-l-105 {
    padding-left: 105px;
}

.p-t-105 {
    padding-top: 105px;
}

.p-r-105 {
    padding-right: 105px;
}

.p-b-105 {
    padding-bottom: 105px;
}

.p-l-110 {
    padding-left: 110px;
}

.p-t-110 {
    padding-top: 110px;
}

.p-r-110 {
    padding-right: 110px;
}

.p-b-110 {
    padding-bottom: 110px;
}

.p-l-115 {
    padding-left: 115px;
}

.p-t-115 {
    padding-top: 115px;
}

.p-r-115 {
    padding-right: 115px;
}

.p-b-115 {
    padding-bottom: 115px;
}

.p-l-120 {
    padding-left: 120px;
}

.p-t-120 {
    padding-top: 120px;
}

.p-r-120 {
    padding-right: 120px;
}

.p-b-120 {
    padding-bottom: 120px;
}

.p-l-125 {
    padding-left: 125px;
}

.p-t-125 {
    padding-top: 125px;
}

.p-r-125 {
    padding-right: 125px;
}

.p-b-125 {
    padding-bottom: 125px;
}

.margin-0 {
    margin: 0px;
}

.padding-0 {
    padding: 0px;
}

.margin-5 {
    margin: 5px;
}

.padding-5 {
    padding: 5px;
}

.margin-10 {
    margin: 10px;
}

.padding-10 {
    padding: 10px;
}

.margin-15 {
    margin: 15px;
}

.padding-15 {
    padding: 15px;
}

.margin-20 {
    margin: 20px;
}

.padding-20 {
    padding: 20px;
}

.margin-25 {
    margin: 25px;
}

.padding-25 {
    padding: 25px;
}

.margin-30 {
    margin: 30px;
}

.padding-30 {
    padding: 30px;
}

.margin-35 {
    margin: 35px;
}

.padding-35 {
    padding: 35px;
}

.margin-40 {
    margin: 40px;
}

.padding-40 {
    padding: 40px;
}

.margin-45 {
    margin: 45px;
}

.padding-45 {
    padding: 45px;
}

.margin-50 {
    margin: 50px;
}

.padding-50 {
    padding: 50px;
}

.margin-55 {
    margin: 55px;
}

.padding-55 {
    padding: 55px;
}

.margin-60 {
    margin: 60px;
}

.padding-60 {
    padding: 60px;
}

.margin-65 {
    margin: 65px;
}

.padding-65 {
    padding: 65px;
}

.margin-70 {
    margin: 70px;
}

.padding-70 {
    padding: 70px;
}

.margin-75 {
    margin: 75px;
}

.padding-75 {
    padding: 75px;
}

.margin-80 {
    margin: 80px;
}

.padding-80 {
    padding: 80px;
}

.margin-85 {
    margin: 85px;
}

.padding-85 {
    padding: 85px;
}

.margin-90 {
    margin: 90px;
}

.padding-90 {
    padding: 90px;
}

.margin-95 {
    margin: 95px;
}

.padding-95 {
    padding: 95px;
}

.margin-100 {
    margin: 100px;
}

.padding-100 {
    padding: 100px;
}

.margin-105 {
    margin: 105px;
}

.padding-105 {
    padding: 105px;
}

.margin-110 {
    margin: 110px;
}

.padding-110 {
    padding: 110px;
}

.margin-115 {
    margin: 115px;
}

.padding-115 {
    padding: 115px;
}

.margin-120 {
    margin: 120px;
}

.padding-120 {
    padding: 120px;
}

.margin-125 {
    margin: 125px;
}

.padding-125 {
    padding: 125px;
}

.padding-0 {
    padding: 0;
}

.font-6 {
    font-size: 6px;
}

.font-7 {
    font-size: 7px;
}

.font-8 {
    font-size: 8px;
}

.font-9 {
    font-size: 9px;
}

.font-10 {
    font-size: 10px;
}

.font-11 {
    font-size: 11px;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px;
}

.font-17 {
    font-size: 17px;
}

.font-18 {
    font-size: 18px;
}

.font-19 {
    font-size: 19px;
}

.font-20 {
    font-size: 20px;
}

.font-21 {
    font-size: 21px;
}

.font-22 {
    font-size: 22px;
}

.font-23 {
    font-size: 23px;
}

.font-24 {
    font-size: 24px;
}

.font-25 {
    font-size: 25px;
}

.font-26 {
    font-size: 26px;
}

.font-27 {
    font-size: 27px;
}

.font-28 {
    font-size: 28px;
}

.font-29 {
    font-size: 29px;
}

.font-30 {
    font-size: 30px;
}

.font-31 {
    font-size: 31px;
}

.font-32 {
    font-size: 32px;
}

.font-33 {
    font-size: 33px;
}

.font-34 {
    font-size: 34px;
}

.font-35 {
    font-size: 35px;
}

.font-36 {
    font-size: 36px;
}

.font-37 {
    font-size: 37px;
}

.font-38 {
    font-size: 38px;
}

.font-39 {
    font-size: 39px;
}

.font-40 {
    font-size: 40px;
}

.font-41 {
    font-size: 41px;
}

.font-42 {
    font-size: 42px;
}

.font-43 {
    font-size: 43px;
}

.font-44 {
    font-size: 44px;
}

.font-45 {
    font-size: 45px;
}

.font-46 {
    font-size: 46px;
}

.font-47 {
    font-size: 47px;
}

.font-48 {
    font-size: 48px;
}

.font-49 {
    font-size: 49px;
}

.font-50 {
    font-size: 50px;
}

* {
    outline: none;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-justify {
    text-align: justify;
}

.no-resize {
    resize: none;
}

.font-bold {
    font-weight: bold;
}

.font-italic {
    font-style: italic;
}

.font-underline {
    text-decoration: underline;
}

.font-line-through {
    text-decoration: line-through;
}

.font-overline {
    text-decoration: overline;
}

.block-header {
    line-height: 46px;
}

.block-header h2 {
    margin: 0 !important;
    font-size: 32px;
    line-height: 46px;
}

@media (max-width: 1200px) {
    .block-header h2 {
        font-size: 22px;
    }
}

.block-header h2 small {
    display: block;
    font-size: 12px;
    margin-top: 8px;
    color: #888;
}

.block-header h2 small a {
    font-weight: 500;
    color: #777;
}

.bg-red {
    background-color: #f44336 !important;
    color: #fff;
}

.bg-red .content .text,
.bg-red .content .number {
    color: #fff !important;
}

.bg-pink {
    background-color: #e91e63 !important;
    color: #fff;
}

.bg-pink .content .text,
.bg-pink .content .number {
    color: #fff !important;
}

.bg-purple {
    background-color: #9c27b0 !important;
    color: #fff;
}

.bg-purple .content .text,
.bg-purple .content .number {
    color: #fff !important;
}

.bg-deep-purple {
    background-color: #673ab7 !important;
    color: #fff;
}

.bg-deep-purple .content .text,
.bg-deep-purple .content .number {
    color: #fff !important;
}

.bg-indigo {
    background-color: #3f51b5 !important;
    color: #fff;
}

.bg-indigo .content .text,
.bg-indigo .content .number {
    color: #fff !important;
}

.bg-blue {
    background-color: #488af4 !important;
    color: #fff;
}

.bg-blue .content .text,
.bg-blue .content .number {
    color: #fff !important;
}

.bg-light-blue {
    background-color: #03a9f4 !important;
    color: #fff;
}

.bg-light-blue .content .text,
.bg-light-blue .content .number {
    color: #fff !important;
}

.bg-cyan {
    background-color: #00bcd4 !important;
    color: #fff;
}

.bg-cyan .content .text,
.bg-cyan .content .number {
    color: #fff !important;
}

.bg-teal {
    background-color: #009688 !important;
    color: #fff;
}

.bg-teal .content .text,
.bg-teal .content .number {
    color: #fff !important;
}

.bg-green {
    background-color: #61d199 !important;
    color: #fff;
}

.bg-green .content .text,
.bg-green .content .number {
    color: #fff !important;
}

.bg-light-green {
    background-color: #8bc34a !important;
    color: #fff;
}

.bg-light-green .content .text,
.bg-light-green .content .number {
    color: #fff !important;
}

.bg-lime {
    background-color: #cddc39 !important;
    color: #fff;
}

.bg-lime .content .text,
.bg-lime .content .number {
    color: #fff !important;
}

.bg-yellow {
    background-color: #ffe821 !important;
    color: #fff;
}

.bg-yellow .content .text,
.bg-yellow .content .number {
    color: #fff !important;
}

.bg-amber {
    background-color: #ffc107 !important;
    color: #fff;
}

.bg-amber .content .text,
.bg-amber .content .number {
    color: #fff !important;
}

.bg-orange {
    background-color: #fc9251 !important;
    color: #fff;
}

.bg-orange .content .text,
.bg-orange .content .number {
    color: #fff !important;
}

.bg-deep-orange {
    background-color: #ff5722 !important;
    color: #fff;
}

.bg-deep-orange .content .text,
.bg-deep-orange .content .number {
    color: #fff !important;
}

.bg-brown {
    background-color: #795548 !important;
    color: #fff;
}

.bg-brown .content .text,
.bg-brown .content .number {
    color: #fff !important;
}

.bg-grey {
    background-color: #9e9e9e !important;
    color: #fff;
}

.bg-grey .content .text,
.bg-grey .content .number {
    color: #fff !important;
}

.bg-blue-grey {
    background-color: #607d8b !important;
    color: #fff;
}

.bg-blue-grey .content .text,
.bg-blue-grey .content .number {
    color: #fff !important;
}

.bg-black {
    background-color: #000000 !important;
    color: #fff;
}

.bg-black .content .text,
.bg-black .content .number {
    color: #fff !important;
}

.bg-white {
    background-color: #ffffff !important;
    color: #fff;
}

.bg-white .content .text,
.bg-white .content .number {
    color: #fff !important;
}

.bg-dark-gray {
    background-color: $dark-gray !important;
    color: #fff;
}

.bg-dark-gray .content .text,
.bg-dark-gray .content .number {
    color: #fff !important;
}

.l-bg-green {
    background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important;
    color: #fff;
}

.l-bg-green .content .text,
.l-bg-green .content .number {
    color: #fff !important;
}

.l-bg-orange {
    background: linear-gradient(45deg, #fda582, #f7cf68) !important;
    color: #fff;
}

.l-bg-orange .content .text,
.l-bg-orange .content .number {
    color: #fff !important;
}

.l-bg-cyan {
    background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
    color: #fff;
}

.l-bg-cyan .content .text,
.l-bg-cyan .content .number {
    color: #fff !important;
}

.l-bg-red {
    background: linear-gradient(45deg, #f08080, #f58787) !important;
    color: #fff;
}

.l-bg-red .content .text,
.l-bg-red .content .number {
    color: #fff !important;
}

.l-bg-purple {
    background: linear-gradient(45deg, #a890d3, #edbae7) !important;
    color: #fff;
}

.l-bg-purple .content .text,
.l-bg-purple .content .number {
    color: #fff !important;
}

.l-bg-purple-dark {
    background: linear-gradient(45deg, #a52dd8, #e29bf1) !important;
    color: #fff;
}

.l-bg-purple-dark .content .text,
.l-bg-purple-dark .content .number {
    color: #fff !important;
}

.col-red {
    color: #f44336 !important;
}

.col-pink {
    color: #e91e63 !important;
}

.col-purple {
    color: #9c27b0 !important;
}

.col-deep-purple {
    color: #673ab7 !important;
}

.col-indigo {
    color: #3f51b5 !important;
}

.col-blue {
    color: #2196f3 !important;
}

.col-light-blue {
    color: #03a9f4 !important;
}

.col-cyan {
    color: #00bcd4 !important;
}

.col-teal {
    color: #009688 !important;
}

.col-green {
    color: #4caf50 !important;
}

.col-light-green {
    color: #8bc34a !important;
}

.col-lime {
    color: #cddc39 !important;
}

.col-yellow {
    color: #ffe821 !important;
}

.col-amber {
    color: #ffc107 !important;
}

.col-orange {
    color: #ff9800 !important;
}

.col-deep-orange {
    color: #ff5722 !important;
}

.col-brown {
    color: #795548 !important;
}

.col-grey {
    color: #9e9e9e !important;
}

.col-blue-grey {
    color: #607d8b !important;
}

.col-black {
    color: #000000 !important;
}

.col-white {
    color: #ffffff !important;
}

.col-dark-gray {
    color: $dark-gray !important;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.icon-button-demo button {
    margin-right: 5px;
    margin-bottom: 12px;
}

.icon-and-text-button-demo button {
    margin-right: 5px;
    margin-bottom: 12px;
    width: 16.66666666666667%;
}

.button-demo ul {
    padding-left: 0;
}

.button-demo ul li {
    list-style: none;
    padding-left: 0;
    display: inline-block;
    margin-right: 7px;
}

.button-demo ul li .btn {
    display: block;
    min-width: 175px;
}

.button-demo .btn {
    margin-right: 8px;
    margin-bottom: 13px;
    min-width: 120px;
}

.right-sidebar .nav-tabs + .tab-content {
    padding: 0;
}

.right-sidebar p {
    margin: 20px 15px 15px 15px;
    font-weight: bold;
    text-align: center;
}

.right-sidebar #settings .setting-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.right-sidebar #settings .setting-list li {
    padding: 15px;
    position: relative;
    border-top: 1px solid #eee;
}

.right-sidebar #settings .setting-list li .switch {
    position: absolute;
    top: 15px;
    right: 5px;
}

.right-sidebar .choose-theme li {
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.white-theme-border {
    border: 1px solid $dark-gray;
}

.rightSetting {
    padding: 20px 25px 0 25px;
}

.rightSetting p {
    font-weight: bold;
    margin: 0;
    border-bottom: 1px solid #eee;
    font-size: 12px;
    text-align: left;
}

.btn-sidebar-light {
    background: 0 0 !important;
    color: $dark-gray !important;
    border: 1px solid $dark-gray !important;
    margin: 10px;
}

@media (min-width: 768px) {
    .navbar-header {
        float: left;
    }

    .navbar-collapse {
        width: auto;
        border-top: 0;
        box-shadow: none;
    }

    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }

    .navbar-toggle {
        display: none;
    }

    .navbar-nav {
        float: left;
        margin: 0;
    }

    .navbar-nav > li {
        float: left;
    }

    .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .container > .navbar-header {
        margin-right: 0;
        margin-left: 0;
    }

    .container > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }

    .container-fluid > .navbar-header {
        margin-right: 0;
        margin-left: 0;
    }

    .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }

    .navbar > .container .navbar-brand {
        margin-left: -15px;
    }

    .navbar > .container-fluid .navbar-brand {
        margin-left: -15px;
    }
}

.container > .navbar-header {
    margin-right: -15px;
    margin-left: -15px;
}

.container > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
}

.container-fluid > .navbar-header {
    margin-right: 0;
    margin-left: 0;
}

.container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
}

@media (max-width: 767px) {
    .navbar {
        height: auto;
    }

    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin-left: 35px;
        width: 73%;
    }

    .navbar .navbar-toggle {
        float: right;
    }

    .navbar .nav-left-menu {
        display: none;
    }

    .navbar .navbar-right .fullscreen {
        display: none;
    }

    .navbar .navbar-header {
        display: inline-block;
        /* margin-bottom: -26px; */
        width: calc(100% - 30px);
    }

    .navbar .nav > li {
        display: inline-block;
    }

    .navbar .nav > li > a {
        padding: 13px 15px 8px 15px;
    }

    .navbar .nav > li > a.js-right-sidebar {
        padding: 9px 15px 8px 15px;
    }

    .navbar .nav .user_profile .dropdown-toggle {
        padding: 7px 0 9px 10px;
    }

    .navbar .navbar-nav {
        margin-top: -10px;
        margin-bottom: 1px;
        margin-left: -7px;
    }

    .navbar .navbar-nav .open .dropdown-menu {
        background-color: #fff;
        position: absolute;
    }

    .navbar .dropdown-menu {
        margin-left: -50px;
    }

    .navbar .js-right-sidebar {
        margin-top: 15px;
    }

    .dt-buttons {
        float: none !important;
        text-align: center;
        margin-bottom: 15px;
    }

    .panel-switch-btn {
        top: 12px;
        right: 0 !important;
    }

    .rtl .navbar .navbar-toggle {
        float: left;
        margin-left: 15px;
        margin-right: 0;
    }

    .rtl .navbar .navbar-header {
        margin-bottom: 0;
    }
}

@media (max-width: 500px) {
    .navbar-nav.nav .dropdown-menu {
        width: 245px;
        right: -80px;
    }

    .navbar-nav.nav .dropdown-menu::before {
        right: 95px;
    }

    .navbar-nav.nav .dropdown-menu::after {
        right: 96px;
    }

    .navbar-nav.nav .user_profile .dropdown-menu::before {
        right: 19px;
    }

    .navbar-nav.nav .user_profile .dropdown-menu::after {
        right: 20px;
    }
}

@media (max-width: 350px) {
    .navbar .nav > li > a {
        padding: 13px 10px 8px 10px;
    }

    .navbar .nav > li > a.js-right-sidebar {
        padding: 9px 10px 8px 10px;
    }

    .navbar .nav .user_profile .dropdown-toggle {
        padding: 7px 0 9px 10px;
    }

    .navbar > .container-fluid .navbar-brand {
        margin-left: 25px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin-left: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1169px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin-left: 20px;
    }
}

body,
html {
    background-color: #fff;
    color: $black;
    font-family: 'Basis Grotesque Pro', Arial, Tahoma, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

button,
input,
select,
a {
    outline: none !important;
}

button:hover,
input:hover,
select:hover,
a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}

.row {
    margin-bottom: 0 !important;
}

.no-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}

section.content {
    margin: 83px 15px 0 275px;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.horizontal-layout section.content {
    margin: 170px 15px 0 15px;
    float: left;
    width: calc(100% - 30px);
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
    width: 15px;
    height: 15px;
}

table .checkbox [type='checkbox'] + label {
    margin: 0;
    height: 20px;
    padding-left: 20px;
    vertical-align: middle;
}

.loading-img-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -60px 0 20px -20px;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

.shadow-style {
    -webkit-box-shadow: 0 7px 18px 0 rgba(62, 57, 107, 0.2);
    box-shadow: 0 7px 18px 0 rgba(62, 57, 107, 0.2);
}

.review-img {
    padding-left: 20px;
    padding-top: 5px;
}

.review-img img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
}

.horizontal-layout .sidebar,
.horizontal-layout .nav-left-menu {
    display: none;
}

.bootstrap-notify-container {
    max-width: 320px;
    text-align: center;
}

@media screen and (max-width: 1169px) {
    .horizontal-layout .sidebar {
        display: block;
    }

    .horizontal-layout .top-sidebar {
        display: none;
    }

    .horizontal-layout section.content {
        margin-top: 100px;
    }
}

@media screen and (min-width: 1400px) {
    .boxed-layout .container {
        width: 1370px;
        max-width: 100%;
    }
}

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.waves-effect .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    background: -webkit-radial-gradient(
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 0.3) 40%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(0, 0, 0, 0.5) 60%,
        rgba(255, 255, 255, 0) 70%
    );
    background: -o-radial-gradient(
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 0.3) 40%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(0, 0, 0, 0.5) 60%,
        rgba(255, 255, 255, 0) 70%
    );
    background: -moz-radial-gradient(
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 0.3) 40%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(0, 0, 0, 0.5) 60%,
        rgba(255, 255, 255, 0) 70%
    );
    background: radial-gradient(
        rgba(0, 0, 0, 0.2) 0,
        rgba(0, 0, 0, 0.3) 40%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(0, 0, 0, 0.5) 60%,
        rgba(255, 255, 255, 0) 70%
    );
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
    -webkit-transform: scale(0) translate(0, 0);
    -moz-transform: scale(0) translate(0, 0);
    -ms-transform: scale(0) translate(0, 0);
    -o-transform: scale(0) translate(0, 0);
    transform: scale(0) translate(0, 0);
    pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
    background: rgba(255, 255, 255, 0.4);
    background: -webkit-radial-gradient(
        rgba(255, 255, 255, 0.2) 0,
        rgba(255, 255, 255, 0.3) 40%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 70%
    );
    background: -o-radial-gradient(
        rgba(255, 255, 255, 0.2) 0,
        rgba(255, 255, 255, 0.3) 40%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 70%
    );
    background: -moz-radial-gradient(
        rgba(255, 255, 255, 0.2) 0,
        rgba(255, 255, 255, 0.3) 40%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 70%
    );
    background: radial-gradient(
        rgba(255, 255, 255, 0.2) 0,
        rgba(255, 255, 255, 0.3) 40%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 70%
    );
}

.waves-effect.waves-classic .waves-ripple {
    background: rgba(0, 0, 0, 0.2);
}

.waves-effect.waves-classic.waves-light .waves-ripple {
    background: rgba(255, 255, 255, 0.4);
}

.waves-notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.waves-button,
.waves-circle {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%);
}

.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    outline: none;
    color: inherit;
    background-color: transparent;
    font-size: 1em;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    z-index: 1;
}

.waves-button {
    padding: 0.85em 1.1em;
    border-radius: 0.2em;
}

.waves-button-input {
    margin: 0;
    padding: 0.85em 1.1em;
}

.waves-input-wrapper {
    border-radius: 0.2em;
    vertical-align: bottom;
}

.waves-input-wrapper.waves-button {
    padding: 0;
}

.waves-input-wrapper .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}

.waves-circle {
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%;
}

.waves-float {
    -webkit-mask-image: none;
    -webkit-box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 1.5px 1px rgba(0, 0, 0, 0.12);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}

.waves-float:active {
    -webkit-box-shadow: 0 8px 20px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 20px 1px rgba(0, 0, 0, 0.3);
}

.waves-block {
    display: block;
}

.slideDown {
    animation-name: slideDown;
    -webkit-animation-name: slideDown;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible !important;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    50% {
        transform: translateY(8%);
    }
    65% {
        transform: translateY(-4%);
    }
    80% {
        transform: translateY(4%);
    }
    95% {
        transform: translateY(-2%);
    }
    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideDown {
    0% {
        -webkit-transform: translateY(-100%);
    }
    50% {
        -webkit-transform: translateY(8%);
    }
    65% {
        -webkit-transform: translateY(-4%);
    }
    80% {
        -webkit-transform: translateY(4%);
    }
    95% {
        -webkit-transform: translateY(-2%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

.slideUp {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible !important;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }
    50% {
        transform: translateY(-8%);
    }
    65% {
        transform: translateY(4%);
    }
    80% {
        transform: translateY(-4%);
    }
    95% {
        transform: translateY(2%);
    }
    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translateY(100%);
    }
    50% {
        -webkit-transform: translateY(-8%);
    }
    65% {
        -webkit-transform: translateY(4%);
    }
    80% {
        -webkit-transform: translateY(-4%);
    }
    95% {
        -webkit-transform: translateY(2%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

.slideLeft {
    animation-name: slideLeft;
    -webkit-animation-name: slideLeft;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes slideLeft {
    0% {
        transform: translateX(150%);
    }
    50% {
        transform: translateX(-8%);
    }
    65% {
        transform: translateX(4%);
    }
    80% {
        transform: translateX(-4%);
    }
    95% {
        transform: translateX(2%);
    }
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slideLeft {
    0% {
        -webkit-transform: translateX(150%);
    }
    50% {
        -webkit-transform: translateX(-8%);
    }
    65% {
        -webkit-transform: translateX(4%);
    }
    80% {
        -webkit-transform: translateX(-4%);
    }
    95% {
        -webkit-transform: translateX(2%);
    }
    100% {
        -webkit-transform: translateX(0%);
    }
}

.slideRight {
    animation-name: slideRight;
    -webkit-animation-name: slideRight;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes slideRight {
    0% {
        transform: translateX(-150%);
    }
    50% {
        transform: translateX(8%);
    }
    65% {
        transform: translateX(-4%);
    }
    80% {
        transform: translateX(4%);
    }
    95% {
        transform: translateX(-2%);
    }
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slideRight {
    0% {
        -webkit-transform: translateX(-150%);
    }
    50% {
        -webkit-transform: translateX(8%);
    }
    65% {
        -webkit-transform: translateX(-4%);
    }
    80% {
        -webkit-transform: translateX(4%);
    }
    95% {
        -webkit-transform: translateX(-2%);
    }
    100% {
        -webkit-transform: translateX(0%);
    }
}

.slideExpandUp {
    animation-name: slideExpandUp;
    -webkit-animation-name: slideExpandUp;
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease -out;
    visibility: visible !important;
}

@keyframes slideExpandUp {
    0% {
        transform: translateY(100%) scaleX(0.5);
    }
    30% {
        transform: translateY(-8%) scaleX(0.5);
    }
    40% {
        transform: translateY(2%) scaleX(0.5);
    }
    50% {
        transform: translateY(0%) scaleX(1.1);
    }
    60% {
        transform: translateY(0%) scaleX(0.9);
    }
    70% {
        transform: translateY(0%) scaleX(1.05);
    }
    80% {
        transform: translateY(0%) scaleX(0.95);
    }
    90% {
        transform: translateY(0%) scaleX(1.02);
    }
    100% {
        transform: translateY(0%) scaleX(1);
    }
}

@-webkit-keyframes slideExpandUp {
    0% {
        -webkit-transform: translateY(100%) scaleX(0.5);
    }
    30% {
        -webkit-transform: translateY(-8%) scaleX(0.5);
    }
    40% {
        -webkit-transform: translateY(2%) scaleX(0.5);
    }
    50% {
        -webkit-transform: translateY(0%) scaleX(1.1);
    }
    60% {
        -webkit-transform: translateY(0%) scaleX(0.9);
    }
    70% {
        -webkit-transform: translateY(0%) scaleX(1.05);
    }
    80% {
        -webkit-transform: translateY(0%) scaleX(0.95);
    }
    90% {
        -webkit-transform: translateY(0%) scaleX(1.02);
    }
    100% {
        -webkit-transform: translateY(0%) scaleX(1);
    }
}

.expandUp {
    animation-name: expandUp;
    -webkit-animation-name: expandUp;
    animation-duration: 0.7s;
    -webkit-animation-duration: 0.7s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible !important;
}

@keyframes expandUp {
    0% {
        transform: translateY(100%) scale(0.6) scaleY(0.5);
    }
    60% {
        transform: translateY(-7%) scaleY(1.12);
    }
    75% {
        transform: translateY(3%);
    }
    100% {
        transform: translateY(0%) scale(1) scaleY(1);
    }
}

@-webkit-keyframes expandUp {
    0% {
        -webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
    }
    60% {
        -webkit-transform: translateY(-7%) scaleY(1.12);
    }
    75% {
        -webkit-transform: translateY(3%);
    }
    100% {
        -webkit-transform: translateY(0%) scale(1) scaleY(1);
    }
}

.fadeIn {
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes fadeIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    60% {
        transform: scale(1.1);
    }
    80% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    60% {
        -webkit-transform: scale(1.1);
    }
    80% {
        -webkit-transform: scale(0.9);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

.expandOpen {
    animation-name: expandOpen;
    -webkit-animation-name: expandOpen;
    animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    visibility: visible !important;
}

@keyframes expandOpen {
    0% {
        transform: scale(1.8);
    }
    50% {
        transform: scale(0.95);
    }
    80% {
        transform: scale(1.05);
    }
    90% {
        transform: scale(0.98);
    }
    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes expandOpen {
    0% {
        -webkit-transform: scale(1.8);
    }
    50% {
        -webkit-transform: scale(0.95);
    }
    80% {
        -webkit-transform: scale(1.05);
    }
    90% {
        -webkit-transform: scale(0.98);
    }
    100% {
        -webkit-transform: scale(1);
    }
}

.bigEntrance {
    animation-name: bigEntrance;
    -webkit-animation-name: bigEntrance;
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    visibility: visible !important;
}

@keyframes bigEntrance {
    0% {
        transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
        opacity: 0.2;
    }
    30% {
        transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
        opacity: 1;
    }
    45% {
        transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    60% {
        transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    75% {
        transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    90% {
        transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    100% {
        transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
}

@-webkit-keyframes bigEntrance {
    0% {
        -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
        opacity: 0.2;
    }
    30% {
        -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
        opacity: 1;
    }
    45% {
        -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    60% {
        -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    75% {
        -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    90% {
        -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
        opacity: 1;
    }
}

.hatch {
    animation-name: hatch;
    -webkit-animation-name: hatch;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    visibility: visible !important;
}

@keyframes hatch {
    0% {
        transform: rotate(0deg) scaleY(0.6);
    }
    20% {
        transform: rotate(-2deg) scaleY(1.05);
    }
    35% {
        transform: rotate(2deg) scaleY(1);
    }
    50% {
        transform: rotate(-2deg);
    }
    65% {
        transform: rotate(1deg);
    }
    80% {
        transform: rotate(-1deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes hatch {
    0% {
        -webkit-transform: rotate(0deg) scaleY(0.6);
    }
    20% {
        -webkit-transform: rotate(-2deg) scaleY(1.05);
    }
    35% {
        -webkit-transform: rotate(2deg) scaleY(1);
    }
    50% {
        -webkit-transform: rotate(-2deg);
    }
    65% {
        -webkit-transform: rotate(1deg);
    }
    80% {
        -webkit-transform: rotate(-1deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

.bounce {
    animation-name: bounce;
    -webkit-animation-name: bounce;
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
}

@keyframes bounce {
    0% {
        transform: translateY(0%) scaleY(0.6);
    }
    60% {
        transform: translateY(-100%) scaleY(1.1);
    }
    70% {
        transform: translateY(0%) scaleY(0.95) scaleX(1.05);
    }
    80% {
        transform: translateY(0%) scaleY(1.05) scaleX(1);
    }
    90% {
        transform: translateY(0%) scaleY(0.95) scaleX(1);
    }
    100% {
        transform: translateY(0%) scaleY(1) scaleX(1);
    }
}

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(0%) scaleY(0.6);
    }
    60% {
        -webkit-transform: translateY(-100%) scaleY(1.1);
    }
    70% {
        -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
    }
    80% {
        -webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
    }
    90% {
        -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
    }
    100% {
        -webkit-transform: translateY(0%) scaleY(1) scaleX(1);
    }
}

.pulse {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.7;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.9);
        opacity: 0.7;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }
    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }
}

.floating {
    animation-name: floating;
    -webkit-animation-name: floating;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes floating {
    0% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(8%);
    }
    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes floating {
    0% {
        -webkit-transform: translateY(0%);
    }
    50% {
        -webkit-transform: translateY(8%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

.tossing {
    animation-name: tossing;
    -webkit-animation-name: tossing;
    animation-duration: 2.5s;
    -webkit-animation-duration: 2.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
    0% {
        transform: rotate(-4deg);
    }
    50% {
        transform: rotate(4deg);
    }
    100% {
        transform: rotate(-4deg);
    }
}

@-webkit-keyframes tossing {
    0% {
        -webkit-transform: rotate(-4deg);
    }
    50% {
        -webkit-transform: rotate(4deg);
    }
    100% {
        -webkit-transform: rotate(-4deg);
    }
}

.pullUp {
    animation-name: pullUp;
    -webkit-animation-name: pullUp;
    animation-duration: 1.1s;
    -webkit-animation-duration: 1.1s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
}

@keyframes pullUp {
    0% {
        transform: scaleY(0.1);
    }
    40% {
        transform: scaleY(1.02);
    }
    60% {
        transform: scaleY(0.98);
    }
    80% {
        transform: scaleY(1.01);
    }
    100% {
        transform: scaleY(0.98);
    }
    80% {
        transform: scaleY(1.01);
    }
    100% {
        transform: scaleY(1);
    }
}

@-webkit-keyframes pullUp {
    0% {
        -webkit-transform: scaleY(0.1);
    }
    40% {
        -webkit-transform: scaleY(1.02);
    }
    60% {
        -webkit-transform: scaleY(0.98);
    }
    80% {
        -webkit-transform: scaleY(1.01);
    }
    100% {
        -webkit-transform: scaleY(0.98);
    }
    80% {
        -webkit-transform: scaleY(1.01);
    }
    100% {
        -webkit-transform: scaleY(1);
    }
}

.pullDown {
    animation-name: pullDown;
    -webkit-animation-name: pullDown;
    animation-duration: 1.1s;
    -webkit-animation-duration: 1.1s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
}

@keyframes pullDown {
    0% {
        transform: scaleY(0.1);
    }
    40% {
        transform: scaleY(1.02);
    }
    60% {
        transform: scaleY(0.98);
    }
    80% {
        transform: scaleY(1.01);
    }
    100% {
        transform: scaleY(0.98);
    }
    80% {
        transform: scaleY(1.01);
    }
    100% {
        transform: scaleY(1);
    }
}

@-webkit-keyframes pullDown {
    0% {
        -webkit-transform: scaleY(0.1);
    }
    40% {
        -webkit-transform: scaleY(1.02);
    }
    60% {
        -webkit-transform: scaleY(0.98);
    }
    80% {
        -webkit-transform: scaleY(1.01);
    }
    100% {
        -webkit-transform: scaleY(0.98);
    }
    80% {
        -webkit-transform: scaleY(1.01);
    }
    100% {
        -webkit-transform: scaleY(1);
    }
}

.stretchLeft {
    animation-name: stretchLeft;
    -webkit-animation-name: stretchLeft;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%;
}

@keyframes stretchLeft {
    0% {
        transform: scaleX(0.3);
    }
    40% {
        transform: scaleX(1.02);
    }
    60% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(1);
    }
}

@-webkit-keyframes stretchLeft {
    0% {
        -webkit-transform: scaleX(0.3);
    }
    40% {
        -webkit-transform: scaleX(1.02);
    }
    60% {
        -webkit-transform: scaleX(0.98);
    }
    80% {
        -webkit-transform: scaleX(1.01);
    }
    100% {
        -webkit-transform: scaleX(0.98);
    }
    80% {
        -webkit-transform: scaleX(1.01);
    }
    100% {
        -webkit-transform: scaleX(1);
    }
}

.stretchRight {
    animation-name: stretchRight;
    -webkit-animation-name: stretchRight;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
}

@keyframes stretchRight {
    0% {
        transform: scaleX(0.3);
    }
    40% {
        transform: scaleX(1.02);
    }
    60% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(1);
    }
}

@-webkit-keyframes stretchRight {
    0% {
        -webkit-transform: scaleX(0.3);
    }
    40% {
        -webkit-transform: scaleX(1.02);
    }
    60% {
        -webkit-transform: scaleX(0.98);
    }
    80% {
        -webkit-transform: scaleX(1.01);
    }
    100% {
        -webkit-transform: scaleX(0.98);
    }
    80% {
        -webkit-transform: scaleX(1.01);
    }
    100% {
        -webkit-transform: scaleX(1);
    }
}

/* Material Design Icons ====================================== */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/iconfont/MaterialIcons-Regular.eot);
    /* For IE6-8 */
    src: local('Material Icons'), local('MaterialIcons-Regular'), url(../fonts/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
        url(../fonts/iconfont/MaterialIcons-Regular.woff) format('woff'),
        url(../fonts/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
    font-family: Material Icons;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';
    line-height: 1;
    height: auto;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-26 {
    font-size: 26px;
}

.material-icons.md-28 {
    font-size: 28px;
}

.material-icons.md-30 {
    font-size: 30px;
}

.material-icons.md-32 {
    font-size: 32px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

/* Components styles ====================================== */
.bootstrap-select {
    box-shadow: none !important;
    border-bottom: 1px solid #ddd !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:active {
    outline: none !important;
}

.bootstrap-select .bs-searchbox,
.bootstrap-select .bs-actionsbox,
.bootstrap-select .bs-donebutton {
    padding: 0 0 5px 0;
    border-bottom: 1px solid #e9e9e9;
}

.bootstrap-select .bs-searchbox .form-control,
.bootstrap-select .bs-actionsbox .form-control,
.bootstrap-select .bs-donebutton .form-control {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
    border: none;
    margin-left: 30px;
}

.bootstrap-select .bs-searchbox {
    position: relative;
}

.bootstrap-select .bs-searchbox:after {
    content: '\E8B6';
    font-family: 'Material Icons';
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 25px;
}

.bootstrap-select ul.dropdown-menu {
    margin-top: 0 !important;
}

.bootstrap-select .dropdown-menu li.selected a {
    background-color: #eee !important;
    color: #555 !important;
}

.bootstrap-select .dropdown-menu .active a {
    background-color: transparent;
    color: #333 !important;
}

.bootstrap-select .dropdown-menu .notify {
    background-color: #f44336 !important;
    color: #fff !important;
    border: none !important;
}

.bootstrap-select .dropdown-toggle {
    display: none;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    margin-top: 9px;
}

.dropdown-toggle:after {
    display: none;
}

.select-wrapper .dropdown-trigger {
    border-bottom: none !important;
}

.back-link {
    color: inherit;
    font-size: 15px;
    font-weight: 500;
}

.back-link:hover {
    color: var(--primary);
}

.back-link:hover .material-icons {
    transform: translateX(-5px);
}

.back-link .material-icons {
    font-size: 1.2em;
    margin-right: 0;
    line-height: 1.2em;
    transition: 100ms transform ease-out;
}

.breadcrumb {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background-color: transparent;
}

.breadcrumb:before {
    content: '';
    margin: 0;
}

.breadcrumb li {
    display: inline-block;
}

.breadcrumb li a {
    color: #444;
    text-decoration: none;
}

.breadcrumb li a .material-icons {
    font-size: 18px;
    position: relative;
    top: 0;
    float: left;
    padding-right: 3px;
}

.breadcrumb li .material-icons {
    font-size: 18px;
    position: relative;
    top: 4px;
    float: none;
}

.breadcrumb li.active {
    color: #777;
}

.breadcrumb > li + li:before {
    content: '>\00a0';
    color: #777;
}

.breadcrumb-col-red li a {
    color: #f44336 !important;
    font-weight: bold;
}

.breadcrumb-bg-red {
    background-color: #f44336 !important;
}

.breadcrumb-bg-red li {
    color: #fff !important;
}

.breadcrumb-bg-red li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-red li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-red li + li:before {
    color: #fff;
}

.breadcrumb-col-pink li a {
    color: #e91e63 !important;
    font-weight: bold;
}

.breadcrumb-bg-pink {
    background-color: #e91e63 !important;
}

.breadcrumb-bg-pink li {
    color: #fff !important;
}

.breadcrumb-bg-pink li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-pink li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-pink li + li:before {
    color: #fff;
}

.breadcrumb-col-purple li a {
    color: #9c27b0 !important;
    font-weight: bold;
}

.breadcrumb-bg-purple {
    background-color: #9c27b0 !important;
}

.breadcrumb-bg-purple li {
    color: #fff !important;
}

.breadcrumb-bg-purple li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-purple li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-purple li + li:before {
    color: #fff;
}

.breadcrumb-col-deep-purple li a {
    color: #673ab7 !important;
    font-weight: bold;
}

.breadcrumb-bg-deep-purple {
    background-color: #673ab7 !important;
}

.breadcrumb-bg-deep-purple li {
    color: #fff !important;
}

.breadcrumb-bg-deep-purple li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-deep-purple li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-deep-purple li + li:before {
    color: #fff;
}

.breadcrumb-col-indigo li a {
    color: #3f51b5 !important;
    font-weight: bold;
}

.breadcrumb-bg-indigo {
    background-color: #3f51b5 !important;
}

.breadcrumb-bg-indigo li {
    color: #fff !important;
}

.breadcrumb-bg-indigo li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-indigo li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-indigo li + li:before {
    color: #fff;
}

.breadcrumb-col-blue li a {
    color: #2196f3 !important;
    font-weight: bold;
}

.breadcrumb-bg-blue {
    background-color: #2196f3 !important;
}

.breadcrumb-bg-blue li {
    color: #fff !important;
}

.breadcrumb-bg-blue li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-blue li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-blue li + li:before {
    color: #fff;
}

.breadcrumb-col-light-blue li a {
    color: #03a9f4 !important;
    font-weight: bold;
}

.breadcrumb-bg-light-blue {
    background-color: #03a9f4 !important;
}

.breadcrumb-bg-light-blue li {
    color: #fff !important;
}

.breadcrumb-bg-light-blue li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-light-blue li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-light-blue li + li:before {
    color: #fff;
}

.breadcrumb-col-cyan li a {
    color: #00bcd4 !important;
    font-weight: bold;
}

.breadcrumb-bg-cyan {
    background-color: #00bcd4 !important;
}

.breadcrumb-bg-cyan li {
    color: #fff !important;
}

.breadcrumb-bg-cyan li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-cyan li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-cyan li + li:before {
    color: #fff;
}

.breadcrumb-col-teal li a {
    color: #009688 !important;
    font-weight: bold;
}

.breadcrumb-bg-teal {
    background-color: #009688 !important;
}

.breadcrumb-bg-teal li {
    color: #fff !important;
}

.breadcrumb-bg-teal li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-teal li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-teal li + li:before {
    color: #fff;
}

.breadcrumb-col-green li a {
    color: #4caf50 !important;
    font-weight: bold;
}

.breadcrumb-bg-green {
    background-color: #4caf50 !important;
}

.breadcrumb-bg-green li {
    color: #fff !important;
}

.breadcrumb-bg-green li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-green li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-green li + li:before {
    color: #fff;
}

.breadcrumb-col-light-green li a {
    color: #8bc34a !important;
    font-weight: bold;
}

.breadcrumb-bg-light-green {
    background-color: #8bc34a !important;
}

.breadcrumb-bg-light-green li {
    color: #fff !important;
}

.breadcrumb-bg-light-green li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-light-green li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-light-green li + li:before {
    color: #fff;
}

.breadcrumb-col-lime li a {
    color: #cddc39 !important;
    font-weight: bold;
}

.breadcrumb-bg-lime {
    background-color: #cddc39 !important;
}

.breadcrumb-bg-lime li {
    color: #fff !important;
}

.breadcrumb-bg-lime li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-lime li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-lime li + li:before {
    color: #fff;
}

.breadcrumb-col-yellow li a {
    color: #ffe821 !important;
    font-weight: bold;
}

.breadcrumb-bg-yellow {
    background-color: #ffe821 !important;
}

.breadcrumb-bg-yellow li {
    color: #fff !important;
}

.breadcrumb-bg-yellow li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-yellow li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-yellow li + li:before {
    color: #fff;
}

.breadcrumb-col-amber li a {
    color: #ffc107 !important;
    font-weight: bold;
}

.breadcrumb-bg-amber {
    background-color: #ffc107 !important;
}

.breadcrumb-bg-amber li {
    color: #fff !important;
}

.breadcrumb-bg-amber li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-amber li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-amber li + li:before {
    color: #fff;
}

.breadcrumb-col-orange li a {
    color: #ff9800 !important;
    font-weight: bold;
}

.breadcrumb-bg-orange {
    background-color: #ff9800 !important;
}

.breadcrumb-bg-orange li {
    color: #fff !important;
}

.breadcrumb-bg-orange li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-orange li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-orange li + li:before {
    color: #fff;
}

.breadcrumb-col-deep-orange li a {
    color: #ff5722 !important;
    font-weight: bold;
}

.breadcrumb-bg-deep-orange {
    background-color: #ff5722 !important;
}

.breadcrumb-bg-deep-orange li {
    color: #fff !important;
}

.breadcrumb-bg-deep-orange li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-deep-orange li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-deep-orange li + li:before {
    color: #fff;
}

.breadcrumb-col-brown li a {
    color: #795548 !important;
    font-weight: bold;
}

.breadcrumb-bg-brown {
    background-color: #795548 !important;
}

.breadcrumb-bg-brown li {
    color: #fff !important;
}

.breadcrumb-bg-brown li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-brown li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-brown li + li:before {
    color: #fff;
}

.breadcrumb-col-grey li a {
    color: #9e9e9e !important;
    font-weight: bold;
}

.breadcrumb-bg-grey {
    background-color: #9e9e9e !important;
}

.breadcrumb-bg-grey li {
    color: #fff !important;
}

.breadcrumb-bg-grey li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-grey li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-grey li + li:before {
    color: #fff;
}

.breadcrumb-col-blue-grey li a {
    color: #607d8b !important;
    font-weight: bold;
}

.breadcrumb-bg-blue-grey {
    background-color: #607d8b !important;
}

.breadcrumb-bg-blue-grey li {
    color: #fff !important;
}

.breadcrumb-bg-blue-grey li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-blue-grey li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-blue-grey li + li:before {
    color: #fff;
}

.breadcrumb-col-black li a {
    color: #000000 !important;
    font-weight: bold;
}

.breadcrumb-bg-black {
    background-color: #000000 !important;
}

.breadcrumb-bg-black li {
    color: #fff !important;
}

.breadcrumb-bg-black li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-black li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-black li + li:before {
    color: #fff;
}

.breadcrumb-col-white li a {
    color: #ffffff !important;
    font-weight: bold;
}

.breadcrumb-bg-white {
    background-color: #ffffff !important;
}

.breadcrumb-bg-white li {
    color: #fff !important;
}

.breadcrumb-bg-white li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-white li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-white li + li:before {
    color: #fff;
}

.breadcrumb-col-dark-gray li a {
    color: $dark-gray !important;
    font-weight: bold;
}

.breadcrumb-bg-dark-gray {
    background-color: $dark-gray !important;
}

.breadcrumb-bg-dark-gray li {
    color: #fff !important;
}

.breadcrumb-bg-dark-gray li a {
    color: #fff;
    font-weight: bold;
}

.breadcrumb-bg-dark-gray li a .material-icons {
    padding-bottom: 8px;
}

.breadcrumb-bg-dark-gray li + li:before {
    color: #fff;
}

.breadcrumb-style {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.3);
    padding-left: 20px !important;
}

[type='checkbox'] + label {
    padding-left: 26px;
    height: 25px;
    line-height: 21px;
    font-size: 13px;
    font-weight: normal;
    vertical-align: middle;
}

[type='checkbox']:checked + label:before {
    top: -4px;
    left: -2px;
    width: 11px;
    height: 19px;
}

[type='checkbox']:checked.chk-col-red + label:before {
    border-right: 2px solid #f44336;
    border-bottom: 2px solid #f44336;
}

[type='checkbox']:checked.chk-col-pink + label:before {
    border-right: 2px solid #e91e63;
    border-bottom: 2px solid #e91e63;
}

[type='checkbox']:checked.chk-col-purple + label:before {
    border-right: 2px solid #9c27b0;
    border-bottom: 2px solid #9c27b0;
}

[type='checkbox']:checked.chk-col-deep-purple + label:before {
    border-right: 2px solid #673ab7;
    border-bottom: 2px solid #673ab7;
}

[type='checkbox']:checked.chk-col-indigo + label:before {
    border-right: 2px solid #3f51b5;
    border-bottom: 2px solid #3f51b5;
}

[type='checkbox']:checked.chk-col-blue + label:before {
    border-right: 2px solid #2196f3;
    border-bottom: 2px solid #2196f3;
}

[type='checkbox']:checked.chk-col-light-blue + label:before {
    border-right: 2px solid #03a9f4;
    border-bottom: 2px solid #03a9f4;
}

[type='checkbox']:checked.chk-col-cyan + label:before {
    border-right: 2px solid #00bcd4;
    border-bottom: 2px solid #00bcd4;
}

[type='checkbox']:checked.chk-col-teal + label:before {
    border-right: 2px solid #009688;
    border-bottom: 2px solid #009688;
}

[type='checkbox']:checked.chk-col-green + label:before {
    border-right: 2px solid #4caf50;
    border-bottom: 2px solid #4caf50;
}

[type='checkbox']:checked.chk-col-light-green + label:before {
    border-right: 2px solid #8bc34a;
    border-bottom: 2px solid #8bc34a;
}

[type='checkbox']:checked.chk-col-lime + label:before {
    border-right: 2px solid #cddc39;
    border-bottom: 2px solid #cddc39;
}

[type='checkbox']:checked.chk-col-yellow + label:before {
    border-right: 2px solid #ffe821;
    border-bottom: 2px solid #ffe821;
}

[type='checkbox']:checked.chk-col-amber + label:before {
    border-right: 2px solid #ffc107;
    border-bottom: 2px solid #ffc107;
}

[type='checkbox']:checked.chk-col-orange + label:before {
    border-right: 2px solid #ff9800;
    border-bottom: 2px solid #ff9800;
}

[type='checkbox']:checked.chk-col-deep-orange + label:before {
    border-right: 2px solid #ff5722;
    border-bottom: 2px solid #ff5722;
}

[type='checkbox']:checked.chk-col-brown + label:before {
    border-right: 2px solid #795548;
    border-bottom: 2px solid #795548;
}

[type='checkbox']:checked.chk-col-grey + label:before {
    border-right: 2px solid #9e9e9e;
    border-bottom: 2px solid #9e9e9e;
}

[type='checkbox']:checked.chk-col-blue-grey + label:before {
    border-right: 2px solid #607d8b;
    border-bottom: 2px solid #607d8b;
}

[type='checkbox']:checked.chk-col-black + label:before {
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
}

[type='checkbox']:checked.chk-col-white + label:before {
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
}

[type='checkbox']:checked.chk-col-dark-gray + label:before {
    border-right: 2px solid $dark-gray;
    border-bottom: 2px solid $dark-gray;
}

:checked.chk-col-red + span:after {
    color: #f44336;
}

:checked.chk-col-pink + span:after {
    color: #e91e63;
}

:checked.chk-col-purple + span:after {
    color: #9c27b0;
}

:checked.chk-col-deep-purple + span:after {
    color: #673ab7;
}

:checked.chk-col-indigo + span:after {
    color: #3f51b5;
}

:checked.chk-col-blue + span:after {
    color: #2196f3;
}

:checked.chk-col-light-blue + span:after {
    color: #03a9f4;
}

:checked.chk-col-cyan + span:after {
    color: #00bcd4;
}

:checked.chk-col-teal + span:after {
    color: #009688;
}

:checked.chk-col-green + span:after {
    color: #4caf50;
}

:checked.chk-col-light-green + span:after {
    color: #8bc34a;
}

:checked.chk-col-lime + span:after {
    color: #cddc39;
}

:checked.chk-col-yellow + span:after {
    color: #ffe821;
}

:checked.chk-col-amber + span:after {
    color: #ffc107;
}

:checked.chk-col-orange + span:after {
    color: #ff9800;
}

:checked.chk-col-deep-orange + span:after {
    color: #ff5722;
}

:checked.chk-col-brown + span:after {
    color: #795548;
}

:checked.chk-col-grey + span:after {
    color: #9e9e9e;
}

:checked.chk-col-blue-grey + span:after {
    color: #607d8b;
}

:checked.chk-col-black + span:after {
    color: #000000;
}

:checked.chk-col-white + span:after {
    color: #ffffff;
}

:checked.chk-col-dark-gray + span:after {
    color: $dark-gray;
}

[type='checkbox'].filled-in:checked + label:after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #26a69a;
    background-color: #26a69a;
    z-index: 0;
}

[type='checkbox'].filled-in:checked + label:before {
    border-right: 2px solid #fff !important;
    border-bottom: 2px solid #fff !important;
}

[type='checkbox'].filled-in:checked.chk-col-red + label:after {
    border: 2px solid #f44336;
    background-color: #f44336;
}

[type='checkbox'].filled-in:checked.chk-col-pink + label:after {
    border: 2px solid #e91e63;
    background-color: #e91e63;
}

[type='checkbox'].filled-in:checked.chk-col-purple + label:after {
    border: 2px solid #9c27b0;
    background-color: #9c27b0;
}

[type='checkbox'].filled-in:checked.chk-col-deep-purple + label:after {
    border: 2px solid #673ab7;
    background-color: #673ab7;
}

[type='checkbox'].filled-in:checked.chk-col-indigo + label:after {
    border: 2px solid #3f51b5;
    background-color: #3f51b5;
}

[type='checkbox'].filled-in:checked.chk-col-blue + label:after {
    border: 2px solid #2196f3;
    background-color: #2196f3;
}

[type='checkbox'].filled-in:checked.chk-col-light-blue + label:after {
    border: 2px solid #03a9f4;
    background-color: #03a9f4;
}

[type='checkbox'].filled-in:checked.chk-col-cyan + label:after {
    border: 2px solid #00bcd4;
    background-color: #00bcd4;
}

[type='checkbox'].filled-in:checked.chk-col-teal + label:after {
    border: 2px solid #009688;
    background-color: #009688;
}

[type='checkbox'].filled-in:checked.chk-col-green + label:after {
    border: 2px solid #4caf50;
    background-color: #4caf50;
}

[type='checkbox'].filled-in:checked.chk-col-light-green + label:after {
    border: 2px solid #8bc34a;
    background-color: #8bc34a;
}

[type='checkbox'].filled-in:checked.chk-col-lime + label:after {
    border: 2px solid #cddc39;
    background-color: #cddc39;
}

[type='checkbox'].filled-in:checked.chk-col-yellow + label:after {
    border: 2px solid #ffe821;
    background-color: #ffe821;
}

[type='checkbox'].filled-in:checked.chk-col-amber + label:after {
    border: 2px solid #ffc107;
    background-color: #ffc107;
}

[type='checkbox'].filled-in:checked.chk-col-orange + label:after {
    border: 2px solid #ff9800;
    background-color: #ff9800;
}

[type='checkbox'].filled-in:checked.chk-col-deep-orange + label:after {
    border: 2px solid #ff5722;
    background-color: #ff5722;
}

[type='checkbox'].filled-in:checked.chk-col-brown + label:after {
    border: 2px solid #795548;
    background-color: #795548;
}

[type='checkbox'].filled-in:checked.chk-col-grey + label:after {
    border: 2px solid #9e9e9e;
    background-color: #9e9e9e;
}

[type='checkbox'].filled-in:checked.chk-col-blue-grey + label:after {
    border: 2px solid #607d8b;
    background-color: #607d8b;
}

[type='checkbox'].filled-in:checked.chk-col-black + label:after {
    border: 2px solid #000000;
    background-color: #000000;
}

[type='checkbox'].filled-in:checked.chk-col-white + label:after {
    border: 2px solid #ffffff;
    background-color: #ffffff;
}

[type='checkbox'].filled-in:checked.chk-col-dark-gray + label:after {
    border: 2px solid $dark-gray;
    background-color: $dark-gray;
}

[type='radio']:not(:checked) + label {
    padding-left: 26px;
    height: 25px;
    line-height: 25px;
    font-size: 13px;
    font-weight: normal;
}

[type='radio']:checked + label {
    padding-left: 26px;
    height: 25px;
    line-height: 25px;
    font-size: 13px;
    font-weight: normal;
}

[type='radio'] + label {
    vertical-align: middle;
}

[type='radio'].radio-col-red:checked + label:after {
    background-color: #f44336;
    border-color: #f44336;
}

[type='radio'].radio-col-pink:checked + label:after {
    background-color: #e91e63;
    border-color: #e91e63;
}

[type='radio'].radio-col-purple:checked + label:after {
    background-color: #9c27b0;
    border-color: #9c27b0;
}

[type='radio'].radio-col-deep-purple:checked + label:after {
    background-color: #673ab7;
    border-color: #673ab7;
}

[type='radio'].radio-col-indigo:checked + label:after {
    background-color: #3f51b5;
    border-color: #3f51b5;
}

[type='radio'].radio-col-blue:checked + label:after {
    background-color: #2196f3;
    border-color: #2196f3;
}

[type='radio'].radio-col-light-blue:checked + label:after {
    background-color: #03a9f4;
    border-color: #03a9f4;
}

[type='radio'].radio-col-cyan:checked + label:after {
    background-color: #00bcd4;
    border-color: #00bcd4;
}

[type='radio'].radio-col-teal:checked + label:after {
    background-color: #009688;
    border-color: #009688;
}

[type='radio'].radio-col-green:checked + label:after {
    background-color: #4caf50;
    border-color: #4caf50;
}

[type='radio'].radio-col-light-green:checked + label:after {
    background-color: #8bc34a;
    border-color: #8bc34a;
}

[type='radio'].radio-col-lime:checked + label:after {
    background-color: #cddc39;
    border-color: #cddc39;
}

[type='radio'].radio-col-yellow:checked + label:after {
    background-color: #ffe821;
    border-color: #ffe821;
}

[type='radio'].radio-col-amber:checked + label:after {
    background-color: #ffc107;
    border-color: #ffc107;
}

[type='radio'].radio-col-orange:checked + label:after {
    background-color: #ff9800;
    border-color: #ff9800;
}

[type='radio'].radio-col-deep-orange:checked + label:after {
    background-color: #ff5722;
    border-color: #ff5722;
}

[type='radio'].radio-col-brown:checked + label:after {
    background-color: #795548;
    border-color: #795548;
}

[type='radio'].radio-col-grey:checked + label:after {
    background-color: #9e9e9e;
    border-color: #9e9e9e;
}

[type='radio'].radio-col-blue-grey:checked + label:after {
    background-color: #607d8b;
    border-color: #607d8b;
}

[type='radio'].radio-col-black:checked + label:after {
    background-color: #000000;
    border-color: #000000;
}

[type='radio'].radio-col-white:checked + label:after {
    background-color: #ffffff;
    border-color: #ffffff;
}

[type='radio'].radio-col-dark-gray:checked + label:after {
    background-color: $dark-gray;
    border-color: $dark-gray;
}

[type='radio'].with-gap.radio-col-red:checked + label:before {
    border: 2px solid #f44336;
}

[type='radio'].with-gap.radio-col-red:checked + label:after {
    background-color: #f44336;
    border: 2px solid #f44336;
}

[type='radio'].with-gap.radio-col-pink:checked + label:before {
    border: 2px solid #e91e63;
}

[type='radio'].with-gap.radio-col-pink:checked + label:after {
    background-color: #e91e63;
    border: 2px solid #e91e63;
}

[type='radio'].with-gap.radio-col-purple:checked + label:before {
    border: 2px solid #9c27b0;
}

[type='radio'].with-gap.radio-col-purple:checked + label:after {
    background-color: #9c27b0;
    border: 2px solid #9c27b0;
}

[type='radio'].with-gap.radio-col-deep-purple:checked + label:before {
    border: 2px solid #673ab7;
}

[type='radio'].with-gap.radio-col-deep-purple:checked + label:after {
    background-color: #673ab7;
    border: 2px solid #673ab7;
}

[type='radio'].with-gap.radio-col-indigo:checked + label:before {
    border: 2px solid #3f51b5;
}

[type='radio'].with-gap.radio-col-indigo:checked + label:after {
    background-color: #3f51b5;
    border: 2px solid #3f51b5;
}

[type='radio'].with-gap.radio-col-blue:checked + label:before {
    border: 2px solid #2196f3;
}

[type='radio'].with-gap.radio-col-blue:checked + label:after {
    background-color: #2196f3;
    border: 2px solid #2196f3;
}

[type='radio'].with-gap.radio-col-light-blue:checked + label:before {
    border: 2px solid #03a9f4;
}

[type='radio'].with-gap.radio-col-light-blue:checked + label:after {
    background-color: #03a9f4;
    border: 2px solid #03a9f4;
}

[type='radio'].with-gap.radio-col-cyan:checked + label:before {
    border: 2px solid #00bcd4;
}

[type='radio'].with-gap.radio-col-cyan:checked + label:after {
    background-color: #00bcd4;
    border: 2px solid #00bcd4;
}

[type='radio'].with-gap.radio-col-teal:checked + label:before {
    border: 2px solid #009688;
}

[type='radio'].with-gap.radio-col-teal:checked + label:after {
    background-color: #009688;
    border: 2px solid #009688;
}

[type='radio'].with-gap.radio-col-green:checked + label:before {
    border: 2px solid #4caf50;
}

[type='radio'].with-gap.radio-col-green:checked + label:after {
    background-color: #4caf50;
    border: 2px solid #4caf50;
}

[type='radio'].with-gap.radio-col-light-green:checked + label:before {
    border: 2px solid #8bc34a;
}

[type='radio'].with-gap.radio-col-light-green:checked + label:after {
    background-color: #8bc34a;
    border: 2px solid #8bc34a;
}

[type='radio'].with-gap.radio-col-lime:checked + label:before {
    border: 2px solid #cddc39;
}

[type='radio'].with-gap.radio-col-lime:checked + label:after {
    background-color: #cddc39;
    border: 2px solid #cddc39;
}

[type='radio'].with-gap.radio-col-yellow:checked + label:before {
    border: 2px solid #ffe821;
}

[type='radio'].with-gap.radio-col-yellow:checked + label:after {
    background-color: #ffe821;
    border: 2px solid #ffe821;
}

[type='radio'].with-gap.radio-col-amber:checked + label:before {
    border: 2px solid #ffc107;
}

[type='radio'].with-gap.radio-col-amber:checked + label:after {
    background-color: #ffc107;
    border: 2px solid #ffc107;
}

[type='radio'].with-gap.radio-col-orange:checked + label:before {
    border: 2px solid #ff9800;
}

[type='radio'].with-gap.radio-col-orange:checked + label:after {
    background-color: #ff9800;
    border: 2px solid #ff9800;
}

[type='radio'].with-gap.radio-col-deep-orange:checked + label:before {
    border: 2px solid #ff5722;
}

[type='radio'].with-gap.radio-col-deep-orange:checked + label:after {
    background-color: #ff5722;
    border: 2px solid #ff5722;
}

[type='radio'].with-gap.radio-col-brown:checked + label:before {
    border: 2px solid #795548;
}

[type='radio'].with-gap.radio-col-brown:checked + label:after {
    background-color: #795548;
    border: 2px solid #795548;
}

[type='radio'].with-gap.radio-col-grey:checked + label:before {
    border: 2px solid #9e9e9e;
}

[type='radio'].with-gap.radio-col-grey:checked + label:after {
    background-color: #9e9e9e;
    border: 2px solid #9e9e9e;
}

[type='radio'].with-gap.radio-col-blue-grey:checked + label:before {
    border: 2px solid #607d8b;
}

[type='radio'].with-gap.radio-col-blue-grey:checked + label:after {
    background-color: #607d8b;
    border: 2px solid #607d8b;
}

[type='radio'].with-gap.radio-col-black:checked + label:before {
    border: 2px solid #000000;
}

[type='radio'].with-gap.radio-col-black:checked + label:after {
    background-color: #000000;
    border: 2px solid #000000;
}

[type='radio'].with-gap.radio-col-white:checked + label:before {
    border: 2px solid #ffffff;
}

[type='radio'].with-gap.radio-col-white:checked + label:after {
    background-color: #ffffff;
    border: 2px solid #ffffff;
}

[type='radio'].with-gap.radio-col-dark-gray:checked + label:before {
    border: 2px solid $dark-gray;
}

[type='radio'].with-gap.radio-col-dark-gray:checked + label:after {
    background-color: $dark-gray;
    border: 2px solid $dark-gray;
}

[type='radio'].size-20 + span:before,
[type='radio'].size-20 + span:after {
    width: 20px;
    height: 20px;
    margin: 2px 4px;
}

.form-check {
    margin-top: 0.1rem;
    padding-left: 0;
}

.form-check .form-check-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    line-height: 26px;
    margin-bottom: 0;
    -webkit-transition: color 0.3s linear;
    -moz-transition: color 0.3s linear;
    -o-transition: color 0.3s linear;
    -ms-transition: color 0.3s linear;
    transition: color 0.3s linear;
}

.form-check .form-check-sign::before {
    content: ' ';
    display: inline-block;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 0;
    cursor: pointer;
    border-radius: 3px;
    top: 0;
    background-color: transparent;
    border: 1px solid #83838a;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check .form-check-sign::after {
    font-family: 'Material Icons';
    content: '\E876';
    top: 0px;
    text-align: center;
    font-size: 14px;
    opacity: 0;
    color: #555;
    border: 0;
    background-color: inherit;
}

.form-check input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.form-check input[type='checkbox']:checked + .form-check-sign::after {
    opacity: 1;
}

.form-check input[type='checkbox']:disabled + .form-check-sign {
    pointer-events: none;
    opacity: 0.2;
}

.form-check input[type='radio']:disabled + .form-check-sign {
    pointer-events: none;
    opacity: 0.2;
}

.radio .form-check-sign {
    padding-left: 28px;
}

.radio input[type='radio'] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.form-check.disabled .form-check-label {
    color: #9a9a9a;
    opacity: 0.5;
    cursor: not-allowed;
}

.form-control input[type='checkbox']:disabled + .form-check-sign::before {
    cursor: not-allowed;
}

.checkbox input[type='checkbox']:disabled + .form-check-sign::after {
    cursor: not-allowed;
}

.form-check-radio .form-check-sign::before {
    content: ' ';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #83838a;
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 3px;
    padding: 1px;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check-radio .form-check-sign::after {
    content: ' ';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #83838a;
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 3px;
    padding: 1px;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.form-check-radio input[type='radio'] {
    opacity: 0;
}

.form-check-radio input[type='radio'] + .form-check-sign:after {
    opacity: 0;
}

.form-check-radio input[type='radio']:checked + .form-check-sign::after {
    width: 4px;
    height: 4px;
    background-color: #555555;
    border-color: #555555;
    top: 11px;
    left: 11px;
    opacity: 1;
}

.form-check-radio input[type='radio']:disabled + .form-check-sign {
    color: #9a9a9a;
}

.form-check-radio input[type='radio']:disabled + .form-check-sign::before {
    color: #9a9a9a;
}

.form-check-radio input[type='radio']:disabled + .form-check-sign::after {
    color: #9a9a9a;
}

.colorpicker {
    z-index: 1;
}

.colorpicker:before,
.colorpicker:after {
    display: none !important;
}

.dtp div.dtp-date,
.dtp div.dtp-time {
    background: #007d72;
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
    background: #007d72;
    height: 20px !important;
    line-height: 20px !important;
}

.dtp .dtp-buttons {
    text-align: center;
}

.dtp .dtp-buttons .dtp-btn-ok {
    margin-left: 10px;
}

.dtp .dtp-buttons .dtp-btn-clear {
    margin-right: 10px !important;
}

.dtp .p10 > a {
    color: #fff;
}

.dtp div.dtp-actual-year {
    font-size: 1.5em;
    color: #ffffff;
}

.dtp table.dtp-picker-days tr td a.selected {
    background: #007d72;
    color: #fff;
}

.dtp-actual-day {
    height: 20px !important;
    line-height: 20px !important;
}

.dropdown-menu {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    margin-top: -35px !important;
    -webkit-box-shadow: 0 5px 75px 2px rgba(64, 70, 74, 0.2) !important;
    box-shadow: 0 5px 75px 2px rgba(64, 70, 74, 0.2) !important;
    border-radius: 0.35rem;
    border: none;
}

.dropdown-menu .divider {
    margin: 5px 0;
}

.dropdown-menu .header {
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid #eee;
    text-align: center;
    padding: 4px 0 6px 0;
}

.dropdown-menu ul.menu {
    padding-left: 0;
}

.dropdown-menu ul.menu.tasks h4 {
    color: #333;
    font-size: 13px;
    margin: 0 0 8px 0;
}

.dropdown-menu ul.menu.tasks h4 small {
    float: right;
    margin-top: 6px;
}

.dropdown-menu ul.menu.tasks .progress {
    height: 7px;
    margin-bottom: 7px;
}

.dropdown-menu ul.menu .icon-circle {
    width: 36px;
    height: 36px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    display: inline-block;
    float: left;
}

.dropdown-menu ul.menu .icon-circle i {
    font-size: 18px;
    line-height: 36px !important;
}

.dropdown-menu ul.menu .msg-user {
    width: 44px;
    height: 44px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    float: left;
}

.dropdown-menu ul.menu .msg-user img {
    float: left;
}

.dropdown-menu ul.menu li {
    border-bottom: 1px solid #eee;
}

.dropdown-menu ul.menu li:last-child {
    border-bottom: none;
}

.dropdown-menu ul.menu li a {
    padding: 11px 11px;
    text-decoration: none;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 100%;
}

.dropdown-menu ul.menu li a:hover {
    background-color: #e9e9e9;
}

.dropdown-menu ul.menu .menu-info {
    display: inline-block;
    position: relative;
    top: 3px;
    left: 10px;
    float: left;
    width: calc(100% - 45px);
}

.dropdown-menu ul.menu .menu-info h4,
.dropdown-menu ul.menu .menu-info .menu-title {
    margin: 0;
    font-size: 13px;
    color: #333;
    float: left;
    width: 100%;
    line-height: 1;
    font-weight: bold;
}

.dropdown-menu ul.menu .menu-info p,
.dropdown-menu ul.menu .menu-info .menu-desc {
    margin: 0;
    font-size: 11px;
    color: #aaa;
    float: left;
    width: 100%;
    line-height: 20px;
}

.dropdown-menu ul.menu .menu-info p .material-icons,
.dropdown-menu ul.menu .menu-info .menu-desc .material-icons {
    font-size: 13px;
    color: #aaa;
    position: relative;
    top: 3px;
    float: left;
    margin-right: 3px;
    height: 20px;
}

.dropdown-menu .footer a {
    text-align: center;
    border-top: 1px solid #eee;
    padding: 10px 0 5px 0;
    font-size: 13px;
    margin-bottom: -5px;
    color: #ff5e00;
    font-weight: 500;
}

.dropdown-menu .footer a:hover {
    background-color: transparent;
}

.dropdown-menu > li > a {
    padding: 7px 18px;
    color: #666;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-size: 14px;
    line-height: 25px;
    display: block;
}

.dropdown-menu > li > a:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.dropdown-menu > li > a i.material-icons {
    float: left;
    margin-right: 7px;
    margin-top: 2px;
    font-size: 20px;
}

.dropdown-animated {
    -webkit-animation-duration: 0.3s !important;
    -moz-animation-duration: 0.3s !important;
    -o-animation-duration: 0.3s !important;
    animation-duration: 0.3s !important;
}

.dropdown-menu.pull-right.show {
    position: absolute !important;
    left: auto !important;
    right: 0 !important;
    top: 50px !important;
    transform: none !important;
}

.dropzone {
    border: 2px solid transparent !important;
    background-color: #eee !important;
}

.dropzone .dz-message .drag-icon-cph .material-icons {
    font-size: 80px;
    color: #777;
}

.dz-drag-hover {
    border: 2px dashed #888 !important;
}

.feedBody {
    border-left: 1px solid #d6d6d6;
    margin-left: 30px;
    padding-top: 10px;
}

.col-auto {
    padding-left: 15px;
    padding-right: 15px;
}

.text-muted {
    color: #919aa3 !important;
    margin-bottom: 10px;
}

.img-100 {
    width: 100px;
}

.feedBody li h6 {
    line-height: 1.5;
    cursor: pointer;
}

.feedBody li.active-feed .feed-user-img:after {
    border-color: #2ed8b6;
}

.sl-item {
    border-left: 1px solid #13b1e0;
    padding-bottom: 1px;
    padding-left: 15px;
    position: relative;
}

.sl-item:last-child::after {
    border-radius: 100%;
    bottom: 0;
    content: '';
    height: 6px;
    left: -3px;
    position: absolute;
    width: 6px;
}

.sl-item .sl-content i {
    font-size: 12px;
}

.sl-item .sl-content small {
    position: relative;
    top: -4px;
}

.sl-item .sl-content p {
    padding-bottom: 4px;
    position: relative;
}

.sl-item::before {
    background-color: #13b1e0;
    border-radius: 100%;
    content: '';
    height: 12px;
    left: -6.5px;
    position: absolute;
    top: 0;
    width: 12px;
}

.sl-primary {
    border-left-color: #2196f3;
}

.sl-primary:last-child::after {
    background-color: #2196f3;
}

.sl-primary::before {
    background-color: #2196f3;
}

.sl-danger {
    border-left-color: #f44336;
}

.sl-danger:last-child::after {
    background-color: #f44336;
}

.sl-danger::before {
    background-color: #f44336;
}

.sl-success {
    border-left-color: #4caf50;
}

.sl-success:last-child::after {
    background-color: #4caf50;
}

.sl-success::before {
    background-color: #4caf50;
}

.sl-warning {
    border-left-color: #ff5722;
}

.sl-warning:last-child::after {
    background-color: #ff5722;
}

.sl-warning::before {
    background-color: #ff5722;
}

.info-box {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    height: 80px;
    display: flex;
    cursor: default;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.info-box .icon {
    display: inline-block;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.12);
    width: 80px;
}

.info-box .icon i {
    color: #fff;
    font-size: 50px;
    line-height: 80px;
}

.info-box .icon .chart.chart-bar {
    height: 100%;
    line-height: 100px;
}

.info-box .icon .chart.chart-bar canvas {
    vertical-align: baseline !important;
}

.info-box .icon .chart.chart-pie {
    height: 100%;
    line-height: 123px;
}

.info-box .icon .chart.chart-pie canvas {
    vertical-align: baseline !important;
}

.info-box .icon .chart.chart-line {
    height: 100%;
    line-height: 115px;
}

.info-box .icon .chart.chart-line canvas {
    vertical-align: baseline !important;
}

.info-box .content {
    display: inline-block;
    padding: 7px 10px;
}

.info-box .content .text {
    font-size: 13px;
    margin-top: 11px;
    color: #555;
}

.info-box .content .number {
    font-weight: normal;
    font-size: 26px;
    margin-top: -4px;
    color: #555;
}

.info-box.hover-zoom-effect .icon {
    overflow: hidden;
}

.info-box.hover-zoom-effect .icon i {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.info-box.hover-zoom-effect:hover .icon i {
    opacity: 0.4;
    -moz-transform: rotate(-32deg) scale(1.4);
    -ms-transform: rotate(-32deg) scale(1.4);
    -o-transform: rotate(-32deg) scale(1.4);
    -webkit-transform: rotate(-32deg) scale(1.4);
    transform: rotate(-32deg) scale(1.4);
}

.info-box.hover-expand-effect:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: '.';
    position: absolute;
    left: 80px;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    -moz-transition: all 0.95s;
    -o-transition: all 0.95s;
    -webkit-transition: all 0.95s;
    transition: all 0.95s;
}

.info-box.hover-expand-effect:hover:after {
    width: 100%;
}

.info-box-2 {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    height: 80px;
    display: flex;
    cursor: default;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.info-box-2 .icon {
    display: inline-block;
    text-align: center;
    width: 80px;
}

.info-box-2 .icon i {
    color: #fff;
    font-size: 50px;
    line-height: 80px;
}

.info-box-2 .chart.chart-bar {
    height: 100%;
    line-height: 105px;
}

.info-box-2 .chart.chart-bar canvas {
    vertical-align: baseline !important;
}

.info-box-2 .chart.chart-pie {
    height: 100%;
    line-height: 123px;
}

.info-box-2 .chart.chart-pie canvas {
    vertical-align: baseline !important;
}

.info-box-2 .chart.chart-line {
    height: 100%;
    line-height: 115px;
}

.info-box-2 .chart.chart-line canvas {
    vertical-align: baseline !important;
}

.info-box-2 .content {
    display: inline-block;
    padding: 7px 10px;
}

.info-box-2 .content .text {
    font-size: 13px;
    margin-top: 11px;
    color: #555;
}

.info-box-2 .content .number {
    font-weight: normal;
    font-size: 26px;
    margin-top: -4px;
    color: #555;
}

.info-box-2.hover-zoom-effect .icon {
    overflow: hidden;
}

.info-box-2.hover-zoom-effect .icon i {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.info-box-2.hover-zoom-effect:hover .icon i {
    opacity: 0.4;
    -moz-transform: rotate(-32deg) scale(1.4);
    -ms-transform: rotate(-32deg) scale(1.4);
    -o-transform: rotate(-32deg) scale(1.4);
    -webkit-transform: rotate(-32deg) scale(1.4);
    transform: rotate(-32deg) scale(1.4);
}

.info-box-2.hover-expand-effect:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: '.';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    -moz-transition: all 0.95s;
    -o-transition: all 0.95s;
    -webkit-transition: all 0.95s;
    transition: all 0.95s;
}

.info-box-2.hover-expand-effect:hover:after {
    width: 100%;
}

.info-box-3 {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    height: 80px;
    display: flex;
    cursor: default;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.info-box-3 .icon {
    position: absolute;
    right: 10px;
    bottom: 2px;
    text-align: center;
}

.info-box-3 .icon i {
    color: rgba(0, 0, 0, 0.15);
    font-size: 60px;
}

.info-box-3 .chart {
    margin-right: 5px;
}

.info-box-3 .chart.chart-bar {
    height: 100%;
    line-height: 50px;
}

.info-box-3 .chart.chart-bar canvas {
    vertical-align: baseline !important;
}

.info-box-3 .chart.chart-pie {
    height: 100%;
    line-height: 34px;
}

.info-box-3 .chart.chart-pie canvas {
    vertical-align: baseline !important;
}

.info-box-3 .chart.chart-line {
    height: 100%;
    line-height: 40px;
}

.info-box-3 .chart.chart-line canvas {
    vertical-align: baseline !important;
}

.info-box-3 .content {
    display: inline-block;
    padding: 7px 16px;
}

.info-box-3 .content .text {
    font-size: 13px;
    margin-top: 11px;
    color: #555;
}

.info-box-3 .content .number {
    font-weight: normal;
    font-size: 26px;
    margin-top: -4px;
    color: #555;
}

.info-box-3.hover-zoom-effect .icon i {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.info-box-3.hover-zoom-effect:hover .icon i {
    opacity: 0.4;
    -moz-transform: rotate(-32deg) scale(1.4);
    -ms-transform: rotate(-32deg) scale(1.4);
    -o-transform: rotate(-32deg) scale(1.4);
    -webkit-transform: rotate(-32deg) scale(1.4);
    transform: rotate(-32deg) scale(1.4);
}

.info-box-3.hover-expand-effect:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: '.';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    -moz-transition: all 0.95s;
    -o-transition: all 0.95s;
    -webkit-transition: all 0.95s;
    transition: all 0.95s;
}

.info-box-3.hover-expand-effect:hover:after {
    width: 100%;
}

.info-box-4 {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    height: 80px;
    display: flex;
    cursor: default;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.info-box-4 .icon {
    position: absolute;
    right: 10px;
    bottom: 2px;
    text-align: center;
}

.info-box-4 .icon i {
    color: rgba(0, 0, 0, 0.15);
    font-size: 60px;
}

.info-box-4 .chart {
    margin-right: 5px;
}

.info-box-4 .chart.chart-bar {
    height: 100%;
    line-height: 50px;
}

.info-box-4 .chart.chart-bar canvas {
    vertical-align: baseline !important;
}

.info-box-4 .chart.chart-pie {
    height: 100%;
    line-height: 34px;
}

.info-box-4 .chart.chart-pie canvas {
    vertical-align: baseline !important;
}

.info-box-4 .chart.chart-line {
    height: 100%;
    line-height: 40px;
}

.info-box-4 .chart.chart-line canvas {
    vertical-align: baseline !important;
}

.info-box-4 .content {
    display: inline-block;
    padding: 7px 16px;
}

.info-box-4 .content .text {
    font-size: 13px;
    margin-top: 11px;
    color: #555;
}

.info-box-4 .content .number {
    font-weight: normal;
    font-size: 26px;
    margin-top: -4px;
    color: #555;
}

.info-box-4.hover-zoom-effect .icon i {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.info-box-4.hover-zoom-effect:hover .icon i {
    opacity: 0.4;
    -moz-transform: rotate(-32deg) scale(1.4);
    -ms-transform: rotate(-32deg) scale(1.4);
    -o-transform: rotate(-32deg) scale(1.4);
    -webkit-transform: rotate(-32deg) scale(1.4);
    transform: rotate(-32deg) scale(1.4);
}

.info-box-4.hover-expand-effect:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: '.';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    -moz-transition: all 0.95s;
    -o-transition: all 0.95s;
    -webkit-transition: all 0.95s;
    transition: all 0.95s;
}

.info-box-4.hover-expand-effect:hover:after {
    width: 100%;
}

.info-box-new {
    background: #fff;
    padding: 20px 20px 0px 20px;
    color: #463f3f;
    border-radius: 15px;
}

.info-box-new .progress {
    height: 10px;
    border-radius: 20px;
}

.support-box {
    padding: 15px;
    color: #fff;
    margin: 0.5rem 0 1rem 0;
    border-radius: 25px;
}

.info-box1 .text-right h2 {
    color: #44e229;
}

.info-box2 .text-right h2 {
    color: #e66c2f;
}

.info-box3 .text-right h2 {
    color: #58b5f5;
}

.info-box4 .text-right h2 {
    color: #ab92d4;
}

/*
*  Document   : _inputformgroup.scss
*  Author     : RedStar Theme
*  Description: This scss file for input form style classes
*/
.form-check,
label {
    font-size: 12px;
    font-weight: 500;
}

.input-group {
    width: 100%;
    margin-bottom: 20px;
    display: table;
}

.input-group .form-line {
    display: inline-block;
    width: 100%;
    position: relative;
}

.input-group .form-line:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0px;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transition: 0.25s ease-in;
    -o-transition: 0.25s ease-in;
    -webkit-transition: 0.25s ease-in;
    transition: 0.25s ease-in;
    border-bottom: 2px solid #1f91f3;
}

.input-group .form-line + .input-group-addon {
    padding-right: 0;
    padding-left: 10px;
}

.input-group .help-info {
    float: right;
    font-size: 12px;
    margin-top: 5px;
    color: #999;
}

.input-group label.error {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    font-weight: normal;
    color: #f44336;
}

.input-group .form-line.error:after {
    border-bottom: 2px solid #f44336;
}

.input-group .form-line.success:after {
    border-bottom: 2px solid #4caf50;
}

.input-group .form-line.warning:after {
    border-bottom: 2px solid #ffc107;
}

.input-group .form-line.focused:after {
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.input-group .form-line.focused .form-label {
    bottom: 25px;
    left: 0;
    font-size: 12px;
}

.input-group .input-group-addon {
    border: none;
    background-color: transparent;
    padding-left: 0;
    font-weight: bold;
    display: table-cell;
}

.input-group .input-group-addon .material-icons {
    font-size: 18px;
    color: #555;
}

.input-group input[type='text'],
.input-group .form-control {
    box-shadow: none;
    padding-left: 0;
    margin: 0;
    font-size: 13px;
}

.input-group .form-control:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
}

.input-group.input-group-sm .input-group-addon i {
    font-size: 14px;
}

.input-group.input-group-sm .form-control {
    font-size: 12px;
}

.input-group.input-group-lg .input-group-addon i {
    font-size: 26px;
}

.input-group.input-group-lg .form-control {
    font-size: 18px;
}

.form-control-label {
    text-align: right;
}

.form-control-label label {
    margin-top: 8px;
}

.form-horizontal .form-group {
    margin-bottom: 0;
}

.form-group {
    width: 100%;
    margin-bottom: 25px;
}

.form-group .form-control {
    width: 100%;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid $gray-line-color;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    padding-left: 0;
}

.form-group input.form-control {
    margin: 0;
    font-size: 13px;
}

.form-group .help-info {
    float: right;
    font-size: 12px;
    margin-top: 5px;
    color: #999;
}

.form-group label.error {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    font-weight: normal;
    color: #f44336;
}

.form-group .form-line {
    width: 100%;
    position: relative;
}

.form-group .form-line:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 0;
    bottom: -1px;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transition: 0.25s ease-in;
    -o-transition: 0.25s ease-in;
    -webkit-transition: 0.25s ease-in;
    transition: 0.25s ease-in;
    border-bottom: 2px solid #1f91f3;
}

.form-group .form-line .form-label {
    font-weight: normal;
    color: #aaa;
    position: absolute;
    top: 10px;
    left: 0;
    cursor: text;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

.form-group .form-line.error:after {
    border-bottom: 2px solid #f44336;
}

.form-group .form-line.success:after {
    border-bottom: 2px solid #4caf50;
}

.form-group .form-line.warning:after {
    border-bottom: 2px solid #ffc107;
}

.form-group .form-line.focused:after {
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.form-group .form-line.focused .form-label {
    top: -10px;
    left: 0;
    font-size: 12px;
}

.form-group-sm .form-label {
    font-size: 12px;
}

.form-group-sm .form-line.focused .form-label {
    bottom: 20px;
    font-size: 10px;
}

.form-group-lg .form-label {
    font-size: 18px;
}

.form-group-lg .form-line.focused .form-label {
    bottom: 35px;
    font-size: 12px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: transparent;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.show > .dropdown-menu {
    display: block;
}

.spinner .input-group-addon {
    /*position: relative;*/
    position: absolute;
    top: 10px;
    right: 0;
}

.spinner .input-group-addon .spin-up i {
    position: absolute;
    left: 0;
}

.spinner .input-group-addon .spin-down i {
    position: absolute;
    left: 0;
    bottom: -15px;
}

.bootstrap-select .btn {
    color: #333;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
    display: table-cell;
}

.form-check {
    margin-bottom: 0.1rem;
    padding-left: 0;
}

.form-check .form-check-label {
    cursor: pointer;
    padding-left: 25px;
    position: relative;
    padding-right: 15px;
    line-height: 18px;
    font-size: 13px;
}

.form-check .form-check-label span {
    display: block;
    position: absolute;
    left: -1px;
    top: -1px;
    transition-duration: 0.2s;
    padding-left: 0;
}

.form-check .form-check-input {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    margin: 0;
    z-index: -1;
    left: 0;
    pointer-events: none;
}

.form-check .form-check-input:checked + .form-check-sign:before {
    animation: rippleOn 0.5s;
}

.form-check .form-check-input:checked + .form-check-sign .check {
    background: var(--primary);
}

.form-check .form-check-input:checked + .form-check-sign .check:before {
    color: #ffffff;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    animation: checkboxOn 0.3s forwards;
}

.form-check .form-check-sign {
    vertical-align: middle;
    position: relative;
    top: -2px;
    float: left;
    padding-right: 10px;
    display: inline-block;
}

.form-check .form-check-sign:before {
    display: block;
    position: absolute;
    left: 0;
    content: '';
    background-color: rgba(0, 0, 0, 0.84);
    width: 18px;
    height: 18px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    top: 0;
    transform: scale3d(2.3, 2.3, 1);
}

.form-check .form-check-sign .check {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.54);
    overflow: hidden;
    z-index: 1;
    border-radius: 3px;
}

.form-check .form-check-sign .check:before {
    position: absolute;
    content: '';
    transform: rotate(45deg);
    display: block;
    margin-top: -3px;
    margin-left: 7px;
    width: 0;
    color: #ffffff;
    height: 0;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    animation: checkboxOff 0.3s forwards;
}

.label {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px;
    padding: 3px 10px 1px 10px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    width: max-content;
    justify-content: center;
    align-items: center;
}

.label-default {
    background-color: #777;
}

.label-primary {
    background-color: #1f91f3;
}

.label-success {
    background-color: $success;
}

.label-info {
    background-color: #00b0e4;
}

.label-warning {
    background-color: $warning;
}

.label-danger {
    background-color: $danger;
}

.overlay {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 10;
}

.overlay-open .sidebar {
    margin-left: 0;
    z-index: 99999999;
}

.sidebar {
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    background: #fdfdfd;
    width: 260px;
    overflow: hidden;
    display: inline-block;
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    left: 0;
    -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1030 !important;
    /*.legal {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #eee;
        padding: 15px;
        overflow: hidden;

        .copyright {
            font-size: 13px;
            @include three-dots-overflow();

            a {
                font-weight: bold;
                text-decoration: none;
            }
        }

        .version {
            @include three-dots-overflow();
            margin-top: 5px;
            font-size: 13px;
        }
    }*/
}

.sidebar .user-info {
    padding: 13px 15px 12px 15px;
    white-space: nowrap;
    position: relative;
    border-bottom: 1px solid #e9e9e9;
    height: 135px;
}

.sidebar .user-info .image {
    margin-right: 12px;
    display: inline-block;
}

.sidebar .user-info .image img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    vertical-align: bottom !important;
}

.sidebar .user-info .info-container {
    cursor: default;
    display: block;
    position: relative;
    top: 25px;
}

.sidebar .user-info .info-container .name {
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    max-width: 200px;
    color: #fff;
}

.sidebar .user-info .info-container .email {
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    max-width: 200px;
    color: #fff;
}

.sidebar .user-info .info-container .user-helper-dropdown {
    position: absolute;
    right: -3px;
    bottom: -12px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    color: #fff;
}

.sidebar .menu {
    position: relative;
    overflow-y: auto;
    height: 100vh;
}

.sidebar .menu .list {
    list-style: none;
    padding-left: 0;
}

.sidebar .menu .list li.active > :first-child span {
    font-weight: bold;
}

.sidebar .menu .list .header {
    font-size: 12px;
    font-weight: 500;
    padding: 15px 16px 5px;
}

.sidebar .menu .list i.material-icons {
    margin-top: 9px;
    font-size: 16px;
}

.sidebar .menu .list .menu-toggle:after,
.sidebar .menu .list .menu-toggle:before {
    position: absolute;
    top: calc(50% - 14px);
    right: 17px;
    font-size: 19px;
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.sidebar .menu .list .menu-toggle:before {
    content: '+';
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sidebar .menu .list .menu-toggle:after {
    content: '\2013';
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
}

.sidebar .menu .list .menu-toggle.toggled:before {
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
}

.sidebar .menu .list .menu-toggle.toggled:after {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sidebar .menu .list a {
    color: #747474;
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    width: 100%;
    padding: 10px 13px;
    font-size: 14px;
}

.sidebar .menu .list a:hover,
.sidebar .menu .list a:active,
.sidebar .menu .list a:focus {
    text-decoration: none !important;
}

.sidebar .menu .list a small {
    position: absolute;
    top: calc(50% - 7.5px);
    right: 15px;
}

.sidebar .menu .list a span {
    margin: 7px 0 7px 12px;
    color: #333;
    font-size: 14px;
    overflow: hidden;
}

.sidebar .menu .list .ml-menu {
    list-style: none;
    display: none;
    padding-left: 0;
}

.sidebar .menu .list .ml-menu span {
    font-weight: normal;
    font-size: 14px;
    margin: 3px 0 1px 6px;
}

.sidebar .menu .list .ml-menu li a {
    padding-left: 41px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.sidebar .menu .list .ml-menu li a:before {
    font-family: 'Material Icons';
    position: relative;
    font-size: 13px;
    height: 20px;
    top: 0px;
    right: 5px;
}

.sidebar .menu .list .ml-menu li .ml-menu li a {
    padding-left: 80px;
}

.sidebar .menu .list .ml-menu li .ml-menu .ml-menu li a {
    padding-left: 95px;
}

.right-sidebar {
    width: 280px;
    height: calc(100vh - 60px);
    position: fixed;
    right: -300px;
    top: 60px;
    background: #fdfdfd;
    z-index: 1030 !important;
    -webkit-box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.right-sidebar.open {
    right: 0;
}

.right-sidebar .nav-tabs {
    font-weight: 500;
    font-size: 13px;
    width: 100%;
    margin-left: 2px;
}

.right-sidebar .nav-tabs li {
    text-align: center;
}

.right-sidebar .nav-tabs li > a {
    margin-right: 0;
}

.right-sidebar .nav-tabs li:first-child {
    width: 45%;
}

.right-sidebar .nav-tabs li:last-child {
    width: 55%;
}

.boxed-layout .sidebar {
    left: auto;
}

.boxed-layout.side-closed .sidebar {
    left: 0;
}

.horizontal-menu {
    width: 100%;
    text-align: center;
}

.top-sidebar {
    float: left;
    width: 100%;
    position: fixed;
    z-index: 9;
    top: 60px;
    background-color: #f0f0f0;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
}

.top-sidebar ul.horizontal-list {
    overflow: visible !important;
    margin: 0;
    display: inline-block;
    height: 60px;
}

.top-sidebar ul.horizontal-list a {
    color: #747474;
    float: left;
    width: 100%;
}

.top-sidebar ul.horizontal-list li {
    float: left;
    position: relative;
    padding: 20px 20px;
}

.top-sidebar ul.horizontal-list li:hover > ul {
    display: block !important;
    position: absolute;
    top: 63px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 9;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
}

.top-sidebar ul.horizontal-list li:hover > ul.mega-ml-menu {
    position: relative;
    top: 0;
}

.top-sidebar ul.horizontal-list li ul li {
    width: 100%;
    padding: 10px 15px;
    text-align: left;
}

.top-sidebar ul.horizontal-list ul.ml-menu {
    display: none !important;
    width: 200px;
}

.top-sidebar ul.horizontal-list ul.ml-menu li:hover ul {
    top: 0;
    left: 100%;
}

.top-sidebar ul.horizontal-list ul.ml-menu .menu-toggle {
    position: relative;
}

.top-sidebar ul.horizontal-list ul.ml-menu .menu-toggle:after {
    content: '\2023';
    position: absolute;
    right: 0;
    font-size: 25px;
    top: 0;
    line-height: 22px;
}

.top-sidebar ul.horizontal-list i.material-icons {
    margin-top: 1px;
    margin-right: 3px;
    float: left;
    font-size: 20px;
}

.top-sidebar ul.horizontal-list .menu-toggle::after {
    content: '\2304';
    position: absolute;
    top: 15px;
    right: 0;
}

.top-sidebar .slimScrollDiv {
    overflow: visible !important;
}

.menu_dark .sidebar {
    background: #2c303b;
    font-weight: 500;
}

.menu_dark .sidebar .menu .list a {
    color: #c3bfbf;
}

.menu_dark .sidebar .menu .list a span {
    color: #c3bfbf;
}

.menu_dark .sidebar .menu .list a:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.menu_dark .sidebar .menu .list .header {
    font-size: 12px;
    font-weight: 500;
    padding: 15px 16px 5px;
    background: transparent;
    color: rgba(255, 255, 255, 0.7);
}

.menu_dark .sidebar .menu .list .active .menu-toggle {
    background-color: #373a42f7;
}

.ms-container {
    width: auto !important;
}

.ms-container .ms-list {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ms-container .ms-list.ms-focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
}

.ms-container .ms-selectable,
.ms-container .ms-selection {
    min-width: 250px !important;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
    color: #000000 !important;
    background-color: #e6e6e6 !important;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selectable li.ms-elem-selection,
.ms-container .ms-selection li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
    padding: 9px 15px 6px 15px !important;
}

.ms-container .ms-optgroup-label {
    padding: 5px 0 0 8px !important;
}

.navbar {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(247, 247, 247);
    position: fixed;
    /* max-width: 1172px; */
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    width: auto;
    padding: 0;
    justify-content: center;
    height: 60px;
}

.navbar .navbar-inner {
    width: 100%;
    height: 60px;
    max-width: none;
    padding: 0;
}

.navbar .navbar-brand {
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

.navbar .navbar-custom-right-menu {
    float: right;
}

.navbar .navbar-toggle {
    text-decoration: none;
    color: #fff;
    width: 20px;
    height: 20px;
    margin-top: -4px;
    margin-right: 17px;
}

.navbar .navbar-toggle:before {
    content: '\E8D5';
    font-family: 'Material Icons';
    font-size: 26px;
}

.navbar .navbar-collapse.in {
    overflow: visible;
}

.navbar .nav.navbar-nav {
    display: block;
}

.navbar .container-fluid,
.navbar .container {
    display: block;
}

.navbar .dropdown-menu ul.menu li {
    width: 100%;
}

.navbar .nav-left-menu {
    margin: 3px 15px;
}

.ls-closed .sidebar {
    margin-left: -300px;
}

/* .ls-closed section.content {
    margin-left: 15px;
} */

.ls-closed .bars:after,
.ls-closed .bars:before {
    font-family: 'Material Icons';
    font-size: 24px;
    position: absolute;
    color: #fff;
    left: 10px;
    top: 3px;
    margin-right: 10px;
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ls-closed .bars:before {
    content: '\E5D2';
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.ls-closed .bars:after {
    content: '\E5C4';
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
}

/* .ls-closed .navbar-brand {
    margin-left: 15px;
} */

.overlay-open .bars:before {
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
}

.overlay-open .bars:after {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.overlay-open.ls-closed .sidebar {
    margin-left: 0;
}

.navbar-header {
    padding: 0;
    margin-top: -5px;
    background-color: #000;
    width: 260px;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.navbar-header .bars {
    float: left;
    text-decoration: none;
}

.logo-name {
    color: white;
    font-size: 24px;
    font-weight: 400;
}

.navbar-nav > li > a {
    padding: 7px 7px 2px 7px;
    margin-top: 20px;
    margin-left: 5px;
}

.navbar-nav.navbar-right {
    float: right !important;
    margin-right: -15px;
    margin-top: -12px;
}

.navbar-nav .dropdown-menu {
    margin-top: 60px !important;
    width: 325px;
    right: 0;
    left: auto;
    position: absolute !important;
    top: 0;
    padding: 0;
}

.navbar-nav .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -7px;
    right: 19px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #eee;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
}

.navbar-nav .dropdown-menu::after {
    content: '';
    position: absolute;
    top: -6px;
    right: 20px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
}

.navbar-nav .dropdown-menu ul.menu .menu-info p {
    line-height: 1;
}

.navbar-nav .dropdown-menu ul.menu .menu-info p .material-icons {
    display: inline-block;
}

.navbar-nav .dropdown-menu li.footer {
    width: 100%;
    height: 45px;
}

.navbar-nav .dropdown-menu .header {
    line-height: 2;
}

.navbar-nav .material-icons {
    line-height: 1;
    height: 24px;
}

.navbar-nav .user_profile .dropdown-toggle img {
    border-radius: 50%;
}

.navbar-nav .user_profile .dropdown-menu .body {
    border: 2px solid #e2e5e5;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
}

.navbar-nav .user_profile .dropdown-item.active,
.dropdown-item:active {
    color: #0175bf;
}

.navbar-nav .user_profile .user_dw_menu li {
    width: 100%;
    border-bottom: 2px solid #e4e6e6;
}

.navbar-nav .user_profile .user_dw_menu li a {
    line-height: 24px;
    color: inherit;
    padding: 8px 15px;
    font-weight: 400;
}

.navbar-nav .user_profile .user_dw_menu li a:hover {
    color: var(--primary);
}

.navbar-nav .user_profile .user_dw_menu li a.active {
    color: var(--primary);
}

.navbar-nav .user_profile .user_dw_menu li i {
    float: left;
    padding-right: 5px;
}

.navbar-nav .user_profile .user_dw_menu li:last-child {
    border-bottom: 0;
}

.navbar-nav .user_profile .dropdown-menu {
    width: 200px;
    right: -10px;
}

.label-count {
    position: absolute;
    top: 0;
    right: 4px;
    font-size: 10px;
    line-height: 15px;
    background-color: #000;
    padding: 3px 7px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 30px;
}

.navbar-brand {
    float: left;
    font-size: 18px;
    line-height: 20px;
}

.navbar-brand img {
    float: left;
}

.navbar-brand span {
    line-height: 32px;
    padding-left: 10px;
}

.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.collapse {
    display: none;
}

.navbar-nav {
    margin: 0px -15px;
}

.navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav > li {
    position: relative;
    display: block;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

/* nav.navbar {
    min-height: 60px;
} */

.col-red .navbar .navbar-brand,
.col-red .navbar .navbar-brand:hover,
.col-red .navbar .navbar-brand:active,
.col-red .navbar .navbar-brand:focus {
    color: #fff;
}

.col-red .navbar .nav > li > a:hover,
.col-red .navbar .nav > li > a:focus,
.col-red .navbar .nav .open > a,
.col-red .navbar .nav .open > a:hover,
.col-red .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-red .navbar .nav > li > a {
    color: #fff;
}

.col-red .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-red .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-pink .navbar .navbar-brand,
.col-pink .navbar .navbar-brand:hover,
.col-pink .navbar .navbar-brand:active,
.col-pink .navbar .navbar-brand:focus {
    color: #fff;
}

.col-pink .navbar .nav > li > a:hover,
.col-pink .navbar .nav > li > a:focus,
.col-pink .navbar .nav .open > a,
.col-pink .navbar .nav .open > a:hover,
.col-pink .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-pink .navbar .nav > li > a {
    color: #fff;
}

.col-pink .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-pink .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-purple .navbar .navbar-brand,
.col-purple .navbar .navbar-brand:hover,
.col-purple .navbar .navbar-brand:active,
.col-purple .navbar .navbar-brand:focus {
    color: #fff;
}

.col-purple .navbar .nav > li > a:hover,
.col-purple .navbar .nav > li > a:focus,
.col-purple .navbar .nav .open > a,
.col-purple .navbar .nav .open > a:hover,
.col-purple .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-purple .navbar .nav > li > a {
    color: #fff;
}

.col-purple .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-purple .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-deep-purple .navbar .navbar-brand,
.col-deep-purple .navbar .navbar-brand:hover,
.col-deep-purple .navbar .navbar-brand:active,
.col-deep-purple .navbar .navbar-brand:focus {
    color: #fff;
}

.col-deep-purple .navbar .nav > li > a:hover,
.col-deep-purple .navbar .nav > li > a:focus,
.col-deep-purple .navbar .nav .open > a,
.col-deep-purple .navbar .nav .open > a:hover,
.col-deep-purple .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-deep-purple .navbar .nav > li > a {
    color: #fff;
}

.col-deep-purple .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-deep-purple .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-indigo .navbar .navbar-brand,
.col-indigo .navbar .navbar-brand:hover,
.col-indigo .navbar .navbar-brand:active,
.col-indigo .navbar .navbar-brand:focus {
    color: #fff;
}

.col-indigo .navbar .nav > li > a:hover,
.col-indigo .navbar .nav > li > a:focus,
.col-indigo .navbar .nav .open > a,
.col-indigo .navbar .nav .open > a:hover,
.col-indigo .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-indigo .navbar .nav > li > a {
    color: #fff;
}

.col-indigo .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-indigo .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-blue .navbar .navbar-brand,
.col-blue .navbar .navbar-brand:hover,
.col-blue .navbar .navbar-brand:active,
.col-blue .navbar .navbar-brand:focus {
    color: #fff;
}

.col-blue .navbar .nav > li > a:hover,
.col-blue .navbar .nav > li > a:focus,
.col-blue .navbar .nav .open > a,
.col-blue .navbar .nav .open > a:hover,
.col-blue .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-blue .navbar .nav > li > a {
    color: #fff;
}

.col-blue .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-blue .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-light-blue .navbar .navbar-brand,
.col-light-blue .navbar .navbar-brand:hover,
.col-light-blue .navbar .navbar-brand:active,
.col-light-blue .navbar .navbar-brand:focus {
    color: #fff;
}

.col-light-blue .navbar .nav > li > a:hover,
.col-light-blue .navbar .nav > li > a:focus,
.col-light-blue .navbar .nav .open > a,
.col-light-blue .navbar .nav .open > a:hover,
.col-light-blue .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-light-blue .navbar .nav > li > a {
    color: #fff;
}

.col-light-blue .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-light-blue .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-cyan .navbar .navbar-brand,
.col-cyan .navbar .navbar-brand:hover,
.col-cyan .navbar .navbar-brand:active,
.col-cyan .navbar .navbar-brand:focus {
    color: #fff;
}

.col-cyan .navbar .nav > li > a:hover,
.col-cyan .navbar .nav > li > a:focus,
.col-cyan .navbar .nav .open > a,
.col-cyan .navbar .nav .open > a:hover,
.col-cyan .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-cyan .navbar .nav > li > a {
    color: #fff;
}

.col-cyan .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-cyan .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-teal .navbar .navbar-brand,
.col-teal .navbar .navbar-brand:hover,
.col-teal .navbar .navbar-brand:active,
.col-teal .navbar .navbar-brand:focus {
    color: #fff;
}

.col-teal .navbar .nav > li > a:hover,
.col-teal .navbar .nav > li > a:focus,
.col-teal .navbar .nav .open > a,
.col-teal .navbar .nav .open > a:hover,
.col-teal .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-teal .navbar .nav > li > a {
    color: #fff;
}

.col-teal .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-teal .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-green .navbar .navbar-brand,
.col-green .navbar .navbar-brand:hover,
.col-green .navbar .navbar-brand:active,
.col-green .navbar .navbar-brand:focus {
    color: #fff;
}

.col-green .navbar .nav > li > a:hover,
.col-green .navbar .nav > li > a:focus,
.col-green .navbar .nav .open > a,
.col-green .navbar .nav .open > a:hover,
.col-green .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-green .navbar .nav > li > a {
    color: #fff;
}

.col-green .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-green .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-light-green .navbar .navbar-brand,
.col-light-green .navbar .navbar-brand:hover,
.col-light-green .navbar .navbar-brand:active,
.col-light-green .navbar .navbar-brand:focus {
    color: #fff;
}

.col-light-green .navbar .nav > li > a:hover,
.col-light-green .navbar .nav > li > a:focus,
.col-light-green .navbar .nav .open > a,
.col-light-green .navbar .nav .open > a:hover,
.col-light-green .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-light-green .navbar .nav > li > a {
    color: #fff;
}

.col-light-green .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-light-green .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-lime .navbar .navbar-brand,
.col-lime .navbar .navbar-brand:hover,
.col-lime .navbar .navbar-brand:active,
.col-lime .navbar .navbar-brand:focus {
    color: #fff;
}

.col-lime .navbar .nav > li > a:hover,
.col-lime .navbar .nav > li > a:focus,
.col-lime .navbar .nav .open > a,
.col-lime .navbar .nav .open > a:hover,
.col-lime .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-lime .navbar .nav > li > a {
    color: #fff;
}

.col-lime .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-lime .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-yellow .navbar .navbar-brand,
.col-yellow .navbar .navbar-brand:hover,
.col-yellow .navbar .navbar-brand:active,
.col-yellow .navbar .navbar-brand:focus {
    color: #fff;
}

.col-yellow .navbar .nav > li > a:hover,
.col-yellow .navbar .nav > li > a:focus,
.col-yellow .navbar .nav .open > a,
.col-yellow .navbar .nav .open > a:hover,
.col-yellow .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-yellow .navbar .nav > li > a {
    color: #fff;
}

.col-yellow .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-yellow .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-amber .navbar .navbar-brand,
.col-amber .navbar .navbar-brand:hover,
.col-amber .navbar .navbar-brand:active,
.col-amber .navbar .navbar-brand:focus {
    color: #fff;
}

.col-amber .navbar .nav > li > a:hover,
.col-amber .navbar .nav > li > a:focus,
.col-amber .navbar .nav .open > a,
.col-amber .navbar .nav .open > a:hover,
.col-amber .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-amber .navbar .nav > li > a {
    color: #fff;
}

.col-amber .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-amber .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-orange .navbar .navbar-brand,
.col-orange .navbar .navbar-brand:hover,
.col-orange .navbar .navbar-brand:active,
.col-orange .navbar .navbar-brand:focus {
    color: #fff;
}

.col-orange .navbar .nav > li > a:hover,
.col-orange .navbar .nav > li > a:focus,
.col-orange .navbar .nav .open > a,
.col-orange .navbar .nav .open > a:hover,
.col-orange .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-orange .navbar .nav > li > a {
    color: #fff;
}

.col-orange .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-orange .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-deep-orange .navbar .navbar-brand,
.col-deep-orange .navbar .navbar-brand:hover,
.col-deep-orange .navbar .navbar-brand:active,
.col-deep-orange .navbar .navbar-brand:focus {
    color: #fff;
}

.col-deep-orange .navbar .nav > li > a:hover,
.col-deep-orange .navbar .nav > li > a:focus,
.col-deep-orange .navbar .nav .open > a,
.col-deep-orange .navbar .nav .open > a:hover,
.col-deep-orange .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-deep-orange .navbar .nav > li > a {
    color: #fff;
}

.col-deep-orange .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-deep-orange .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-brown .navbar .navbar-brand,
.col-brown .navbar .navbar-brand:hover,
.col-brown .navbar .navbar-brand:active,
.col-brown .navbar .navbar-brand:focus {
    color: #fff;
}

.col-brown .navbar .nav > li > a:hover,
.col-brown .navbar .nav > li > a:focus,
.col-brown .navbar .nav .open > a,
.col-brown .navbar .nav .open > a:hover,
.col-brown .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-brown .navbar .nav > li > a {
    color: #fff;
}

.col-brown .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-brown .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-grey .navbar .navbar-brand,
.col-grey .navbar .navbar-brand:hover,
.col-grey .navbar .navbar-brand:active,
.col-grey .navbar .navbar-brand:focus {
    color: #fff;
}

.col-grey .navbar .nav > li > a:hover,
.col-grey .navbar .nav > li > a:focus,
.col-grey .navbar .nav .open > a,
.col-grey .navbar .nav .open > a:hover,
.col-grey .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-grey .navbar .nav > li > a {
    color: #fff;
}

.col-grey .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-grey .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-blue-grey .navbar .navbar-brand,
.col-blue-grey .navbar .navbar-brand:hover,
.col-blue-grey .navbar .navbar-brand:active,
.col-blue-grey .navbar .navbar-brand:focus {
    color: #fff;
}

.col-blue-grey .navbar .nav > li > a:hover,
.col-blue-grey .navbar .nav > li > a:focus,
.col-blue-grey .navbar .nav .open > a,
.col-blue-grey .navbar .nav .open > a:hover,
.col-blue-grey .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-blue-grey .navbar .nav > li > a {
    color: #fff;
}

.col-blue-grey .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-blue-grey .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-black .navbar .navbar-brand,
.col-black .navbar .navbar-brand:hover,
.col-black .navbar .navbar-brand:active,
.col-black .navbar .navbar-brand:focus {
    color: #fff;
}

.col-black .navbar .nav > li > a:hover,
.col-black .navbar .nav > li > a:focus,
.col-black .navbar .nav .open > a,
.col-black .navbar .nav .open > a:hover,
.col-black .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-black .navbar .nav > li > a {
    color: #fff;
}

.col-black .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-black .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-white .navbar .navbar-brand,
.col-white .navbar .navbar-brand:hover,
.col-white .navbar .navbar-brand:active,
.col-white .navbar .navbar-brand:focus {
    color: #fff;
}

.col-white .navbar .nav > li > a:hover,
.col-white .navbar .nav > li > a:focus,
.col-white .navbar .nav .open > a,
.col-white .navbar .nav .open > a:hover,
.col-white .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-white .navbar .nav > li > a {
    color: #fff;
}

.col-white .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-white .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.col-dark-gray .navbar .navbar-brand,
.col-dark-gray .navbar .navbar-brand:hover,
.col-dark-gray .navbar .navbar-brand:active,
.col-dark-gray .navbar .navbar-brand:focus {
    color: #fff;
}

.col-dark-gray .navbar .nav > li > a:hover,
.col-dark-gray .navbar .nav > li > a:focus,
.col-dark-gray .navbar .nav .open > a,
.col-dark-gray .navbar .nav .open > a:hover,
.col-dark-gray .navbar .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-dark-gray .navbar .nav > li > a {
    color: #fff;
}

.col-dark-gray .navbar .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
}

.col-dark-gray .navbar .bars:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.side-closed .sidebar {
    /*margin-left: -300px;*/
    width: 50px;
}

.side-closed .sidebar .menu .list li .menu-toggle:before,
.side-closed .sidebar .menu .list li .menu-toggle:after {
    content: '';
}

.side-closed .sidebar .menu .list li span {
    display: none;
}

.side-closed.submenu-closed .sidebar .menu .list li .ml-menu,
.side-closed.submenu-closed .sidebar .menu .list .header {
    display: none !important;
}

.side-closed.submenu-closed .navbar-header {
    width: 50px;
}

.side-closed.submenu-closed .navbar-header .navbar-brand {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
}

.side-closed.submenu-closed .navbar-header .navbar-brand span {
    display: none;
}

.side-closed section.content {
    margin-left: 65px;
}

.side-closed .navbar-brand {
    margin-left: 30px;
}

.side-closed.side-closed-hover .sidebar {
    width: 260px;
}

.side-closed.side-closed-hover .sidebar .menu .list li .menu-toggle:before {
    content: '+';
}

.side-closed.side-closed-hover .sidebar .menu .list li .menu-toggle:after {
    content: '\2013';
}

.side-closed.side-closed-hover .sidebar .menu .list li span {
    display: block;
}

.side-closed.side-closed-hover section.content {
    margin-left: 275px;
}

.sidemenu-collapse {
    padding: 0 15px;
}

.sidemenu-collapse:hover {
    text-decoration: none;
    color: #fff;
    background-color: transparent;
}

@media screen and (min-width: 601px) {
    nav,
    nav .nav-wrapper i,
    nav a.button-collapse,
    nav a.button-collapse i {
        height: 30px;
        line-height: 30px;
    }
}

.boxed-layout .container > .navbar-header {
    margin-left: 0;
}

.nav-tabs {
    border-bottom: 2px solid #eee;
}

.nav-tabs > li {
    position: relative;
    top: 3px;
    left: -2px;
}

.nav-tabs > li > a {
    border: none !important;
    color: #999 !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:active,
.nav-tabs > li > a:focus {
    background-color: transparent !important;
}

.nav-tabs > li > a:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 0;
    border-bottom: 2px solid #2196f3;
    bottom: 2px;
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -moz-transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
}

.nav-tabs > li > a .material-icons {
    position: relative;
    top: 7px;
    margin-bottom: 8px;
}

.nav-tabs li a.active {
    color: #222 !important;
}

.nav-tabs li a.active:hover,
.nav-tabs li a.active:active,
.nav-tabs li a.active:focus {
    background-color: transparent !important;
}

.nav-tabs li a.active:before {
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.nav-tabs + .tab-content {
    padding: 15px 0;
}

.nav-tabs.tab-col-red > li > a:before {
    border-bottom: 2px solid #f44336;
}

.nav-tabs.tab-col-pink > li > a:before {
    border-bottom: 2px solid #e91e63;
}

.nav-tabs.tab-col-purple > li > a:before {
    border-bottom: 2px solid #9c27b0;
}

.nav-tabs.tab-col-deep-purple > li > a:before {
    border-bottom: 2px solid #673ab7;
}

.nav-tabs.tab-col-indigo > li > a:before {
    border-bottom: 2px solid #3f51b5;
}

.nav-tabs.tab-col-blue > li > a:before {
    border-bottom: 2px solid #2196f3;
}

.nav-tabs.tab-col-light-blue > li > a:before {
    border-bottom: 2px solid #03a9f4;
}

.nav-tabs.tab-col-cyan > li > a:before {
    border-bottom: 2px solid #00bcd4;
}

.nav-tabs.tab-col-teal > li > a:before {
    border-bottom: 2px solid #009688;
}

.nav-tabs.tab-col-green > li > a:before {
    border-bottom: 2px solid #4caf50;
}

.nav-tabs.tab-col-light-green > li > a:before {
    border-bottom: 2px solid #8bc34a;
}

.nav-tabs.tab-col-lime > li > a:before {
    border-bottom: 2px solid #cddc39;
}

.nav-tabs.tab-col-yellow > li > a:before {
    border-bottom: 2px solid #ffe821;
}

.nav-tabs.tab-col-amber > li > a:before {
    border-bottom: 2px solid #ffc107;
}

.nav-tabs.tab-col-orange > li > a:before {
    border-bottom: 2px solid #ff9800;
}

.nav-tabs.tab-col-deep-orange > li > a:before {
    border-bottom: 2px solid #ff5722;
}

.nav-tabs.tab-col-brown > li > a:before {
    border-bottom: 2px solid #795548;
}

.nav-tabs.tab-col-grey > li > a:before {
    border-bottom: 2px solid $gray-line-color;
}

.nav-tabs.tab-col-blue-grey > li > a:before {
    border-bottom: 2px solid #607d8b;
}

.nav-tabs.tab-col-black > li > a:before {
    border-bottom: 2px solid #000000;
}

.nav-tabs.tab-col-white > li > a:before {
    border-bottom: 2px solid #ffffff;
}

.nav-tabs.tab-col-dark-gray > li > a:before {
    border-bottom: 2px solid $dark-gray;
}

.search-bar {
    position: fixed;
    top: -100px;
    left: 0;
    z-index: 9999999;
    width: 100%;
    -moz-transition: 0.25s;
    -o-transition: 0.25s;
    -webkit-transition: 0.25s;
    transition: 0.25s;
}

.search-bar.open {
    top: 0;
}

.search-bar .search-icon {
    position: absolute;
    top: 20px;
    left: 14px;
}

.search-bar .search-icon .material-icons {
    font-size: 32px;
    color: #999;
}

.search-bar .close-search {
    position: absolute;
    cursor: pointer;
    font-size: 30px;
    top: 16px;
    right: 18px;
}

.search-bar .close-search .material-icons {
    color: #999;
    opacity: 1;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.search-bar .close-search .material-icons:hover {
    opacity: 0.5;
}

.search-bar input[type='text'] {
    width: 100%;
    font-size: 16px;
    padding: 25px 60px 23px 56px;
    border: none;
    background-color: #fff;
    height: 24px;
}

.switch label {
    font-weight: normal;
    font-size: 13px;
}

.switch label .lever {
    margin: 0 14px;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-red:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(244, 67, 54, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-red {
    background-color: rgba(244, 67, 54, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-red:after {
    background-color: #f44336;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-pink:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-pink {
    background-color: rgba(233, 30, 99, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-pink:after {
    background-color: #e91e63;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-purple:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(156, 39, 176, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-purple {
    background-color: rgba(156, 39, 176, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-purple:after {
    background-color: #9c27b0;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-deep-purple:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-deep-purple {
    background-color: rgba(103, 58, 183, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-deep-purple:after {
    background-color: #673ab7;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-indigo:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-indigo {
    background-color: rgba(63, 81, 181, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-indigo:after {
    background-color: #3f51b5;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-blue:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(33, 150, 243, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-blue {
    background-color: rgba(33, 150, 243, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-blue:after {
    background-color: #2196f3;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-light-blue:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-light-blue {
    background-color: rgba(3, 169, 244, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-light-blue:after {
    background-color: #03a9f4;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-cyan:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-cyan {
    background-color: rgba(0, 188, 212, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-cyan:after {
    background-color: #00bcd4;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-teal:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-teal {
    background-color: rgba(0, 150, 136, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-teal:after {
    background-color: #009688;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-green:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(76, 175, 80, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-green {
    background-color: rgba(76, 175, 80, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-green:after {
    background-color: #4caf50;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-light-green:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-light-green {
    background-color: rgba(139, 195, 74, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-light-green:after {
    background-color: #8bc34a;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-lime:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-lime {
    background-color: rgba(205, 220, 57, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-lime:after {
    background-color: #cddc39;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-yellow:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 232, 33, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-yellow {
    background-color: rgba(255, 232, 33, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-yellow:after {
    background-color: #ffe821;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-amber:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-amber {
    background-color: rgba(255, 193, 7, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-amber:after {
    background-color: #ffc107;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-orange:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-orange {
    background-color: rgba(255, 152, 0, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-orange:after {
    background-color: #ff9800;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-deep-orange:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-deep-orange {
    background-color: rgba(255, 87, 34, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-deep-orange:after {
    background-color: #ff5722;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-brown:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-brown {
    background-color: rgba(121, 85, 72, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-brown:after {
    background-color: #795548;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-grey:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-grey {
    background-color: rgba(158, 158, 158, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-grey:after {
    background-color: #9e9e9e;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-blue-grey:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-blue-grey {
    background-color: rgba(96, 125, 139, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-blue-grey:after {
    background-color: #607d8b;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-black:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-black {
    background-color: rgba(0, 0, 0, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-black:after {
    background-color: #000000;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-white:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-white {
    background-color: rgba(255, 255, 255, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-white:after {
    background-color: #ffffff;
}

.switch label input[type='checkbox']:checked:not(:disabled) ~ .lever.switch-col-dark-gray:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(136, 136, 136, 0.1);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-dark-gray {
    background-color: rgba(136, 136, 136, 0.5);
}

.switch label input[type='checkbox']:checked + .lever.switch-col-dark-gray:after {
    background-color: $dark-gray;
}

.bootstrap-tagsinput {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
    border: none !important;
}

.bootstrap-tagsinput .tag {
    margin-bottom: 2px;
}

.thumbnail {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.thumbnail p:not(button) {
    color: #999999;
    font-size: 14px;
}

.thumbnail h3 {
    font-weight: bold;
    font-size: 17px;
}

.to-do-list {
    padding-left: 0;
    margin-top: -10px;
    font-size: 12px;
    min-height: 311px;
    float: left;
    width: 100%;
}

.to-do-list li {
    padding: 17px 0;
    border-radius: 3px;
    position: relative;
    cursor: move;
    list-style: none;
    font-size: 14px;
    background: #fff;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
}

.to-do-list li p {
    margin: 0;
    padding-left: 50px;
}

.to-do-list li .todo-check input[type='checkbox'] {
    visibility: hidden;
}

.todo-actionlist {
    position: absolute;
    right: -5px;
    top: 22px;
}

.todo-actionlist a {
    height: 24px;
    width: 24px;
    display: inline-block;
    float: left;
}

.todo-actionlist a i {
    height: 24px;
    width: 24px;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    color: #ccc;
}

.todo-actionlist a:hover i {
    color: #666;
}

.line-through {
    text-decoration: line-through;
}

.todo-action-bar {
    margin-top: 20px;
}

.todo-check {
    width: 20px;
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
}

.todo-check input[type='checkbox'] {
    visibility: hidden;
}

.todo-check label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    border-radius: 2px;
}

.todo-done i {
    font-size: 14px;
}

.todo-remove i {
    font-size: 10px;
}

.inbox-small-cells .todo-check input[type='checkbox'] {
    visibility: hidden;
}

/* Apps styles============================== */
#wrap {
    width: 100%;
    margin: 0 auto;
}

#external-events {
    float: left;
    width: 100%;
    text-align: left;
}

#external-events h4 {
    font-size: 16px;
    margin-top: 0;
    padding-top: 1em;
}

#external-events .fc-event {
    margin: 10px 0;
    cursor: pointer;
    padding: 5px;
}

#external-events p {
    margin: 1.5em 0;
    font-size: 11px;
    color: #666;
}

#external-events p input {
    margin: 0;
    vertical-align: middle;
}

#external-events .material-icons {
    color: #fff;
    font-size: 16px;
}

#calendar {
    float: right;
    width: 100%;
}

.fc-event {
    border: 0 !important;
}

.fc-state-active {
    background: #a389d4 !important;
    color: #fff;
}

.fc-day-grid-event {
    color: white !important;
    padding: 5px;
    text-align: center;
}

.label-event-meeting {
    background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important;
}

.label-event-holiday {
    background: linear-gradient(45deg, #a890d3, #edbae7) !important;
}

.label-event-birthday {
    background: linear-gradient(45deg, #fda582, #f7cf68) !important;
}

.label-event-competition {
    background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
}

.label-event-dinner {
    background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important;
}

.label-event-party {
    background: linear-gradient(45deg, #a890d3, #edbae7) !important;
}

.label-event-Untitled {
    background: linear-gradient(45deg, #fda582, #f7cf68) !important;
}

.fc-content-skeleton thead {
    border-bottom: none;
}

.fc-state-default {
    border: 1px solid #eee;
    background: transparent !important;
    color: #7e869d;
    border-radius: 0;
}

.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
    font-size: 16px;
    color: gray;
    cursor: pointer;
}

.chat-app {
    height: 690px;
}

.chat-app .people-list {
    width: 280px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
}

.chat-app .chat {
    border-left: 1px solid #e8e8e8;
}

.chat-app .list_btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    padding: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    display: none;
    -webkit-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
}

.people-list {
    transition: 0.5s;
}

.people-list .chat-list li {
    padding: 10px 15px;
    list-style: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
}

.people-list .chat-list li:hover {
    background: #efefef;
    cursor: pointer;
}

.people-list .chat-list li.active {
    background: #efefef;
}

.people-list .chat-list li .name {
    font-size: 15px;
}

.people-list .chat-list img {
    width: 45px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.people-list img {
    float: left;
    border: 1px solid #fff;
    -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.people-list .about {
    float: left;
    padding-left: 8px;
}

.people-list .status {
    color: #999;
    font-size: 13px;
}

.chat .chat-header {
    padding: 20px;
    border-bottom: 1px solid #eee;
    border-radius: 0 0.55rem 0 0;
}

.chat .chat-header img {
    float: left;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    width: 45px;
}

.chat .chat-header .chat-about {
    float: left;
    padding-left: 10px;
}

.chat .chat-header .chat-with {
    font-weight: bold;
    font-size: 16px;
}

.chat .chat-header .chat-num-messages {
    color: 434651;
}

.chat .chat-history {
    padding: 20px;
    border-bottom: 2px solid #fff;
    height: 450px;
}

.chat .chat-history ul {
    padding: 0;
}

.chat .chat-history ul li {
    list-style: none;
}

.chat .chat-history .message-data {
    margin-bottom: 15px;
}

.chat .chat-history .message-data .message-data-name {
    font-size: 16px;
    font-weight: 500;
}

.chat .chat-history .message-data-time {
    color: #434651;
    padding-left: 6px;
}

.chat .chat-history .message {
    color: #444;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    border-radius: 7px;
    margin-bottom: 30px;
    width: 90%;
    position: relative;
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
}

.chat .chat-history .my-message {
    background: #e8e8e8;
}

.chat .chat-history .my-message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #e8e8e8;
    border-width: 10px;
    margin-left: -10px;
}

.chat .chat-history .other-message {
    background: #d9e7ea;
}

.chat .chat-history .other-message:after {
    border-bottom-color: #d9e7ea;
    left: 93%;
}

.chat .chat-message {
    padding: 20px;
}

.chat .chat-message textarea {
    width: 100%;
    border: none;
    padding: 10px 20px;
    font: 14px/22px Lato, Arial, sans-serif;
    margin-bottom: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    resize: none;
}

.online {
    margin-right: 3px;
    font-size: 10px;
    color: #86bb71;
}

.offline {
    margin-right: 3px;
    font-size: 10px;
    color: #e38968;
}

.me {
    margin-right: 3px;
    font-size: 10px;
    color: #0498bd;
}

.float-right {
    float: right;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
}

.slimScrollBar {
    z-index: 0 !important;
}

.chat-upload {
    float: right;
}

.contact-detail {
    float: left;
    width: 100%;
}

.contact-detail .fa {
    float: left;
    width: 30px;
    font-size: 20px;
    margin-top: 5px;
}

.contact-detail span {
    float: left;
    width: calc(100% - 30px);
    margin-bottom: 20px;
}

.contact-detail .fa-envelope {
    font-size: 15px;
}

.contact-detail .fa-mobile {
    font-size: 25px;
}

.contact-photo {
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

.contact-photo img {
    margin: 0 auto;
    width: 130px;
    padding: 3px;
    border: 3px solid #d2d6de;
    border-radius: 50% 50% 50% 50%;
}

.contact-usertitle {
    text-align: center;
    margin-top: 5px;
}

.contact-usertitle-name {
    font-size: 20px;
    margin-bottom: 2px;
    font-weight: bold;
    color: #3a405b;
}

.contact-usertitle-job {
    color: #777777;
    font-size: 12px;
    margin-bottom: 5px;
}

.newLabelBtn {
    padding: 20px 0;
    text-align: center;
}

.alert-dismissible .close {
    text-indent: 0;
    color: #fff;
    opacity: 1;
    border: none;
    text-shadow: none;
}

.alert.alert-dismissible {
    color: #ffffff;
}

.contact_list .phone {
    position: relative;
    padding-left: 20px;
}

.contact_list .phone .material-icons {
    position: absolute;
    left: 0;
    font-size: 16px;
    top: 1px;
}

.contact-grid {
    float: left;
    width: 100%;
    text-align: center;
}

.profile-header {
    min-height: 150px;
    color: #fff;
}

.user-name {
    padding: 3px;
    font-size: 22px;
    text-align: center;
    padding-top: 10px;
}

.user-img {
    padding: 3px;
    border-radius: 50% 50% 50% 50%;
    max-width: 112px;
    margin-top: -80px;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
}

.profile-userbuttons {
    text-align: center;
    margin-top: 10px;
}

.contact-grid .phone .material-icons {
    font-size: 16px;
    padding: 4px 7px 40px 0px;
}

/* Pages styles============================== */
.dashboard-flot-chart {
    height: 275px;
}

.dashboard-donut-chart {
    height: 265px;
    text-align: center;
}

.dashboard-line-chart {
    height: 250px;
}

.dashboard-stat-list {
    list-style: none;
    padding-left: 0;
    margin-top: 40px;
}

.dashboard-stat-list li {
    padding: 16px 0 0 0;
}

.dashboard-stat-list li small {
    font-size: 8px;
}

.dashboard-task-infos .progress {
    height: 10px;
    position: relative;
    top: 6px;
}

.totalEarning {
    text-align: center;
    padding: 15px;
    color: #ff9800;
}

.earningProgress .progress {
    height: 9px;
}

.p-15 {
    padding: 15px;
}

.p-10 {
    padding: 10px;
}

.b-b {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.mail_listing .mail-option .btn-group {
    margin-bottom: 5px;
}

#mail-nav .btn {
    min-width: 110px;
}

#mail-nav #mail-folders > li {
    margin: 2px 0;
}

#mail-nav #mail-folders > li a:hover {
    color: #fff;
    background-color: #337ab7;
}

#mail-nav #mail-folders > li.active > a {
    color: #fff;
    background-color: #337ab7;
}

#mail-nav #mail-folders > li.active > a:hover {
    background-color: #32c0c3;
}

#mail-nav #mail-labels {
    float: left;
    width: 100%;
}

#mail-nav #mail-labels li {
    float: left;
}

#mail-nav #mail-labels .material-icons {
    font-size: 16px;
    height: 16px;
    padding: 2px;
    float: left;
}

#mail-nav #online-offline .material-icons {
    font-size: 8px;
    height: 8px;
    padding: 0px 5px 2px 0;
}

#mail-nav #mail-labels li a:hover,
#mail-nav #online-offline li a:hover {
    background-color: #e6e6e6;
}

#mail-nav li a {
    color: #212529;
    padding: 7px 10px;
    display: block;
    border-radius: 4px;
    position: relative;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.composeForm {
    padding: 25px;
}

.inbox-body {
    padding: 20px;
}

.replyBox {
    border: 1px solid rgba(120, 130, 140, 0.13);
    padding: 20px;
}

.inbox-center .table thead th {
    vertical-align: middle;
    padding: 20px 0px 20px 30px;
}

.inbox-center .tbl-checkbox {
    padding: 20px 0px 20px 30px;
}

.email-btn-group {
    position: relative;
    display: -ms-inline-flexbox;
    display: -webkit-inline-box;
    display: inline-flex;
    vertical-align: middle;
}

.max-texts {
    padding: 15px !important;
}

.max-texts a {
    color: #212529;
    padding: 10px 0px 10px 0px;
}

.greenColor .heading {
    font-size: 24px;
    color: #40c952;
    margin-bottom: 20px;
}

.profile-tab-box {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 100%;
}

.skill-progress {
    height: 10px;
}

.tab-all a {
    color: #948f8f !important;
}

.tab-all a.active {
    background-color: #e91e63 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
    color: #ffffff !important;
    border-radius: 30px;
}

/* --------------------------------

Modules - reusable parts of our design

-------------------------------- */
.cd-container {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}

.cd-container::after {
    content: '';
    display: table;
    clear: both;
}

/* --------------------------------

      Main components

      -------------------------------- */
header {
    height: 200px;
    line-height: 200px;
    text-align: center;
    background: #303e49;
}

header h1 {
    color: #ffffff;
    font-size: 18px;
    font-size: 1.125rem;
}

.timelineImgHight {
    width: 150px;
}

@media only screen and (min-width: 1170px) {
    header {
        height: 300px;
        line-height: 300px;
    }

    header h1 {
        font-size: 24px;
        font-size: 1.5rem;
    }
}

#cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}

#cd-timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #d7e4ed;
}

@media only screen and (min-width: 1170px) {
    #cd-timeline {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    #cd-timeline::before {
        left: 50%;
        margin-left: -2px;
    }
}

.cd-timeline-block {
    position: relative;
    margin: 2em 0;
}

.cd-timeline-block::after {
    clear: both;
    content: '';
    display: table;
}

.cd-timeline-block:first-child {
    margin-top: 0;
}

.cd-timeline-block:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
    .cd-timeline-block {
        margin: 4em 0;
    }

    .cd-timeline-block:first-child {
        margin-top: 0;
    }

    .cd-timeline-block:last-child {
        margin-bottom: 0;
    }
}

.cd-timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #e6dfdf, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.cd-timeline-img img {
    display: block;
    width: 48px;
    position: relative;
    left: 30%;
    top: 30%;
    margin-left: -12px;
    margin-top: -12px;
    border-radius: 50%;
}

.cd-timeline-img.cd-picture {
    background: #75ce66;
}

.cd-timeline-img.cd-movie {
    background: #c03b44;
}

.cd-timeline-img.cd-location {
    background: #f0ca45;
}

@media only screen and (min-width: 1170px) {
    .cd-timeline-img {
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
    }

    .cssanimations .cd-timeline-img.is-hidden {
        visibility: hidden;
    }

    .cssanimations .cd-timeline-img.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-1 0.6s;
        -moz-animation: cd-bounce-1 0.6s;
        animation: cd-bounce-1 0.6s;
    }
}

@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(0.5);
    }
    60% {
        opacity: 1;
        -moz-transform: scale(1.2);
    }
    100% {
        -moz-transform: scale(1);
    }
}

@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

.cd-timeline-content {
    position: relative;
    margin-left: 60px;
    background: #f0f1f3;
    border-radius: 0.25em;
    padding: 1em;
    box-shadow: 0 3px 0 #d7e4ed;
}

.cd-timeline-content::after {
    clear: both;
    content: '';
    display: table;
}

.cd-timeline-content h2 {
    color: #303e49;
}

.cd-timeline-content p,
.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
    font-size: 13px;
    font-size: 0.8125rem;
}

.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
    display: inline-block;
}

.cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
}

.cd-timeline-content .cd-read-more {
    float: right;
    padding: 0.8em 1em;
    background: #acb7c0;
    color: #ffffff;
    border-radius: 0.25em;
}

.no-touch .cd-timeline-content .cd-read-more:hover {
    background-color: #bac4cb;
}

.cd-timeline-content .cd-date {
    float: left;
    padding: 0.8em 0;
    opacity: 0.7;
}

.cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #ffffff;
}

@media only screen and (min-width: 768px) {
    .cd-timeline-content h2 {
        font-size: 20px;
        font-size: 1.25rem;
    }

    .cd-timeline-content p {
        font-size: 16px;
        font-size: 1rem;
    }

    .cd-timeline-content .cd-read-more,
    .cd-timeline-content .cd-date {
        font-size: 14px;
        font-size: 0.875rem;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-timeline-content {
        margin-left: 0;
        padding: 1.6em;
        width: 45%;
    }

    .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: #f0f1f3;
    }

    .cd-timeline-content .cd-read-more {
        float: left;
    }

    .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px;
        font-size: 16px;
        font-size: 1rem;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content {
        float: right;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: #f0f1f3;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
        float: right;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
        left: auto;
        right: 122%;
        text-align: right;
    }

    .cssanimations .cd-timeline-content.is-hidden {
        visibility: hidden;
    }

    .cssanimations .cd-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-2 0.6s;
        -moz-animation: cd-bounce-2 0.6s;
        animation: cd-bounce-2 0.6s;
    }
}

@media only screen and (min-width: 1170px) {
    /* inverse bounce effect on even content blocks */
    .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
        -webkit-animation: cd-bounce-2-inverse 0.6s;
        -moz-animation: cd-bounce-2-inverse 0.6s;
        animation: cd-bounce-2-inverse 0.6s;
    }
}

@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }
    60% {
        opacity: 1;
        -moz-transform: translateX(20px);
    }
    100% {
        -moz-transform: translateX(0);
    }
}

@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }
    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px);
    }
    60% {
        opacity: 1;
        -moz-transform: translateX(-20px);
    }
    100% {
        -moz-transform: translateX(0);
    }
}

@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }
    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

/* Plugins styles============================== */
.owl-btns {
    text-align: center;
}

.owl-btns [class*='owl-'] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
}

.owl-btns [class*='owl-']:hover {
    background: #869791;
    color: #fff;
    text-decoration: none;
}

/* Morris */
.morris-hover.morris-default-style {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

/* Flot */
.flot-chart {
    width: 100%;
    height: 320px;
}

.panel-switch-btn {
    position: relative;
    right: 20px;
    z-index: 9;
}

.panel-switch-btn label {
    font-weight: bold !important;
}

.legendLabel {
    width: 85px !important;
    position: relative;
    left: 3px;
}

#multiple_axis_chart .legendLabel {
    width: 160px !important;
}

/* Sparkline */
.sparkline {
    text-align: center;
}

.wizard,
.tabcontrol {
    display: block;
    width: 100%;
    overflow: hidden;
}

.wizard a,
.tabcontrol a {
    outline: 0;
}

.wizard ul,
.tabcontrol ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
}

.wizard ul > li,
.tabcontrol ul > li {
    display: block;
    padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info,
.wizard > .content > .title,
.tabcontrol > .content > .title {
    position: absolute;
    left: -999em;
}

.wizard > .steps {
    position: relative;
    display: block;
    width: 100%;
}

.wizard.vertical > .steps {
    float: left;
    width: 30%;
    clear: none;
}

.wizard.vertical > .steps > ul > li {
    float: none;
    width: 100%;
}

.wizard.vertical > .content {
    float: left;
    margin: 0 0 0.5em 0;
    width: 70%;
    clear: none;
}

.wizard.vertical > .actions {
    float: right;
    width: 100%;
}

.wizard.vertical > .actions > ul > li {
    margin: 0 0 0 1em;
}

.wizard > .steps .number {
    font-size: 1.429em;
}

.wizard > .steps > ul > li {
    width: 25%;
    float: left;
}

.wizard > .actions > ul > li {
    float: left;
    margin: 0 0.5em;
}

.wizard > .steps a {
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em;
    padding: 1em 1em;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard > .steps a:hover,
.wizard > .steps a:active {
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em;
    padding: 1em 1em;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.wizard > .steps .disabled a {
    background: #eee;
    color: #aaa;
    cursor: default;
}

.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
    background: #eee;
    color: #aaa;
    cursor: default;
}

.wizard > .steps .current a {
    background: #2184be;
    color: #fff;
    cursor: default;
}

.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
    background: #2184be;
    color: #fff;
    cursor: default;
}

.wizard > .steps .done a {
    background: #9dc8e2;
    color: #fff;
}

.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
    background: #9dc8e2;
    color: #fff;
}

.wizard > .steps .error a {
    background: #ff3111;
    color: #fff;
}

.wizard > .steps .error a:hover,
.wizard > .steps .error a:active {
    background: #ff3111;
    color: #fff;
}

.wizard > .content {
    border: 1px solid #ddd;
    display: block;
    margin: 0.5em;
    min-height: 35em;
    overflow: hidden;
    position: relative;
    width: auto;
}

.wizard > .actions {
    position: relative;
    display: block;
    text-align: right;
    width: 100%;
}

.wizard > .actions > ul {
    display: inline-block;
    text-align: right;
}

.wizard > .actions a {
    background: #009688;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.wizard > .actions a:hover,
.wizard > .actions a:active {
    background: #009688;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.wizard > .actions .disabled a {
    background: #eee;
    color: #aaa;
}

.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
    background: #eee;
    color: #aaa;
}

.tabcontrol > .steps {
    position: relative;
    display: block;
    width: 100%;
}

.tabcontrol > .steps > ul {
    position: relative;
    margin: 6px 0 0 0;
    top: 1px;
    z-index: 1;
}

.tabcontrol > .steps > ul > li {
    float: left;
    margin: 5px 2px 0 0;
    padding: 1px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover {
    background: #edecec;
    border: 1px solid #bbb;
    padding: 0;
}

.tabcontrol > .steps > ul > li.current {
    background: #fff;
    border: 1px solid #bbb;
    border-bottom: 0 none;
    padding: 0 0 1px 0;
    margin-top: 0;
}

.tabcontrol > .steps > ul > li.current > a {
    padding: 15px 30px 10px 30px;
}

.tabcontrol > .steps > ul > li > a {
    color: #5f5f5f;
    display: inline-block;
    border: 0 none;
    margin: 0;
    padding: 10px 30px;
    text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover {
    text-decoration: none;
}

.tabcontrol > .content {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 35em;
    overflow: hidden;
    border-top: 1px solid #bbb;
    padding-top: 20px;
}

.tabcontrol > .content > .body {
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%;
}

.tabcontrol > .content > .body ul {
    list-style: disc !important;
}

.tabcontrol > .content > .body ul > li {
    display: list-item;
}

.wizard .content {
    min-height: 245px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    overflow-y: auto;
}

.wizard .content .body {
    padding: 15px;
}

.wizard .steps a {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.wizard .steps a:active,
.wizard .steps a:focus,
.wizard .steps a:hover {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.wizard .steps .done a {
    background-color: rgba(0, 150, 136, 0.6);
}

.wizard .steps .done a:hover,
.wizard .steps .done a:active,
.wizard .steps .done a:focus {
    background-color: rgba(0, 150, 136, 0.5);
}

.wizard .steps .error a {
    background-color: #f44336 !important;
}

.wizard .steps .current a {
    background-color: #009688;
}

.wizard .steps .current a:active,
.wizard .steps .current a:focus,
.wizard .steps .current a:hover {
    background-color: #009688;
}

.dataTables_wrapper {
    position: relative;
}

.dataTables_wrapper select {
    border: none;
    border-bottom: 1px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}

.dataTables_wrapper select:active,
.dataTables_wrapper select:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}

.dataTables_wrapper input[type='search'] {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    border: none;
    font-size: 12px;
    height: 26px;
    border-bottom: 1px solid #ddd;
}

.dataTables_wrapper input[type='search']:focus,
.dataTables_wrapper input[type='search']:active {
    border-bottom: 2px solid #1f91f3;
}

.dataTables_wrapper .dt-buttons {
    float: left;
}

.dataTables_wrapper .dt-buttons a.dt-button {
    background-color: #607d8b;
    color: #fff;
    padding: 7px 12px;
    margin-right: 5px;
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    border: none;
    font-size: 13px;
    outline: none;
}

.dataTables_wrapper .dt-buttons a.dt-button:active {
    opacity: 0.8;
}

.dataTables_wrapper .page-link:focus {
    box-shadow: none;
    color: #65686b;
    background-color: transparent;
}

.dataTables_filter {
    float: right;
}

.dt-button-info {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 400px;
    text-align: center;
    background-color: #fff;
    border: 2px solid #999;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    margin-top: -100px;
    margin-left: -200px;
    z-index: 21;
}

.dt-button-info h2 {
    color: #777;
}

.dt-button-info div {
    color: #777;
    margin-bottom: 20px;
}

.dd-handle {
    background-color: #f9f9f9 !important;
}

.dd-handle:hover {
    color: #2196f3;
}

.nestable-dark-theme .dd-handle {
    background: #ccc !important;
    border: 1px solid #999 !important;
}

.dd3-handle {
    background: #999 !important;
}

.dd3-content:hover {
    color: #2196f3;
}

.lg-outer .lg-thumb-item,
.lg-outer .lg-toogle-thumb {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
}

/* Google Maps */
.gmap {
    width: 100%;
    height: 400px;
}

/* JQV Map */
.jqv-map {
    width: 100%;
    height: 600px;
}

.table tbody tr td,
.table tbody tr th {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    vertical-align: middle;
}

.table tbody tr td ul,
.table tbody tr th ul {
    margin: 0;
}

.table tbody tr.primary td,
.table tbody tr.primary th {
    background-color: #1f91f3;
    color: #fff;
}

.table tbody tr.success td,
.table tbody tr.success th {
    background-color: $success;
    color: #fff;
}

.table tbody tr.info td,
.table tbody tr.info th {
    background-color: #00b0e4;
    color: #fff;
}

.table tbody tr.warning td,
.table tbody tr.warning th {
    background-color: $warning;
    color: #fff;
}

.table tbody tr.danger td,
.table tbody tr.danger th {
    background-color: $danger;
    color: #fff;
}

.table thead tr th {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.table-bordered {
    border-top: 1px solid #eee;
}

.table-bordered tbody tr td,
.table-bordered tbody tr th {
    padding: 10px;
    border: 1px solid #eee;
}

.table-bordered thead tr th {
    padding: 10px;
    border: 1px solid #eee;
}

.table-img img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.btn-tbl-edit {
    border-radius: 50% !important;
    background-color: #888;
    padding: 6px !important;
    height: 28px;
    color: #fff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2) !important;
}

.btn-tbl-edit .material-icons {
    font-size: 16px !important;
}

.btn-tbl-edit:hover {
    background-color: #888;
    color: #fff;
}

.btn-tbl-delete {
    border-radius: 50% !important;
    background-color: #f96332;
    padding: 6px !important;
    height: 28px;
    color: #fff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2) !important;
}

.btn-tbl-delete .material-icons {
    font-size: 16px !important;
}

.btn-tbl-delete:hover {
    background-color: #f96332;
    color: #fff;
}

.avatar {
    position: relative;
    width: 30px;
    white-space: nowrap;
    border-radius: 1000px;
    vertical-align: bottom;
    display: inline-block;
}

.avatar img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0;
    border-radius: 1000px;
}

.avatar-sm {
    width: 32px;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.spinner {
    display: inline-block;
}

.spinner-reverse {
    display: inline-block;
}

.order-list li img {
    border: 2px solid #ffffff;
    box-shadow: 0 2px 10px 0 rgba(107, 111, 130, 0.3);
}

.order-list li + li {
    margin-left: -14px;
}

.order-list li .badge {
    background: rgba(255, 255, 255, 0.8);
    color: #6b6f82;
    margin-bottom: 6px;
}

.buttons-copy {
    background-color: #666 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

.buttons-excel {
    background-color: #59bf70 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

.buttons-csv {
    background-color: #2ab9d0 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

.buttons-pdf {
    background-color: #e91e63 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
    color: #fff !important;
    border-radius: 30px !important;
    border: 0px !important;
    height: 30px;
    width: 60px;
}

tr.group,
tr.group:hover {
    background-color: #ddd !important;
}

.tableBody {
    font-size: 14px;
    color: #555;
    padding: 0 15px 0 15px;
}

/* User Interface styles============================== */
.alert {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    border: none;
    color: #fff !important;
    margin-left: 15px;
    margin-bottom: 0;
}

.alert .alert-link {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
}

.alert-success {
    background-color: rgba(24, 206, 15, 0.8);
}

.alert-info {
    background-color: rgba(44, 168, 255, 0.8);
}

.alert-warning {
    background-color: rgba(255, 178, 54, 0.8);
}

.alert-danger {
    background-color: rgba(255, 54, 54, 0.8);
}

.badge {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
}

.list-group-item {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.list-group .active,
.list-group .list-group-item.active {
    background-color: #2196f3;
    border-color: #2196f3;
}

.list-group .active:hover,
.list-group .active:focus,
.list-group .active:active,
.list-group .list-group-item.active:hover,
.list-group .list-group-item.active:focus,
.list-group .list-group-item.active:active {
    background-color: #2196f3;
    border-color: #2196f3;
}

.list-group .active .list-group-item-text,
.list-group .list-group-item.active .list-group-item-text {
    color: #dfe9f1;
    font-size: 13px;
}

.list-group .active .list-group-item-text:hover,
.list-group .active .list-group-item-text:active,
.list-group .active .list-group-item-text:focus,
.list-group .list-group-item.active .list-group-item-text:hover,
.list-group .list-group-item.active .list-group-item-text:active,
.list-group .list-group-item.active .list-group-item-text:focus {
    color: #dfe9f1;
}

.list-group a.list-group-item,
.list-group button.list-group-item {
    color: #555;
}

.list-group a.list-group-item.active,
.list-group button.list-group-item.active {
    color: #dfe9f1;
}

.list-group .list-group-item.active:hover .list-group-item-text,
.list-group .list-group-item.active:focus .list-group-item-text,
.list-group .list-group-item.active:active .list-group-item-text {
    color: #dfe9f1;
}

.list-group .list-group-item {
    text-align: left;
}

.list-group .list-group-item:first-child,
.list-group .list-group-item:last-child {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.list-group .list-group-item .list-group-item-heading {
    font-weight: bold;
    font-size: 17px;
}

.list-group .list-group-item:focus,
.list-group .list-group-item:hover {
    background-color: #f5f5f5;
}

.list-group .list-group-item-success {
    background-color: $success;
    border: none;
    color: #fff;
}

.list-group .list-group-item-success:hover,
.list-group .list-group-item-success:focus {
    background-color: $success;
    color: #fff;
    opacity: 0.8;
}

.list-group .list-group-item-info {
    background-color: #00b0e4;
    border: none;
    color: #fff;
}

.list-group .list-group-item-info:hover,
.list-group .list-group-item-info:focus {
    background-color: #00b0e4;
    color: #fff;
    opacity: 0.8;
}

.list-group .list-group-item-warning {
    background-color: $warning;
    border: none;
    color: #fff;
}

.list-group .list-group-item-warning:hover,
.list-group .list-group-item-warning:focus {
    background-color: $warning;
    color: #fff;
    opacity: 0.8;
}

.list-group .list-group-item-danger {
    background-color: $danger;
    border: none;
    color: #fff;
}

.list-group .list-group-item-danger:hover,
.list-group .list-group-item-danger:focus {
    background-color: $danger;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-red {
    stroke: #f44336;
}

.list-group .list-group-bg-red {
    background-color: #f44336;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-red:hover,
.list-group .list-group-bg-red:focus {
    background-color: #f44336;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-pink {
    stroke: #e91e63;
}

.list-group .list-group-bg-pink {
    background-color: #e91e63;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-pink:hover,
.list-group .list-group-bg-pink:focus {
    background-color: #e91e63;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-purple {
    stroke: #9c27b0;
}

.list-group .list-group-bg-purple {
    background-color: #9c27b0;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-purple:hover,
.list-group .list-group-bg-purple:focus {
    background-color: #9c27b0;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-deep-purple {
    stroke: #673ab7;
}

.list-group .list-group-bg-deep-purple {
    background-color: #673ab7;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-deep-purple:hover,
.list-group .list-group-bg-deep-purple:focus {
    background-color: #673ab7;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-indigo {
    stroke: #3f51b5;
}

.list-group .list-group-bg-indigo {
    background-color: #3f51b5;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-indigo:hover,
.list-group .list-group-bg-indigo:focus {
    background-color: #3f51b5;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-blue {
    stroke: #2196f3;
}

.list-group .list-group-bg-blue {
    background-color: #2196f3;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-blue:hover,
.list-group .list-group-bg-blue:focus {
    background-color: #2196f3;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-light-blue {
    stroke: #03a9f4;
}

.list-group .list-group-bg-light-blue {
    background-color: #03a9f4;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-light-blue:hover,
.list-group .list-group-bg-light-blue:focus {
    background-color: #03a9f4;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-cyan {
    stroke: #00bcd4;
}

.list-group .list-group-bg-cyan {
    background-color: #00bcd4;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-cyan:hover,
.list-group .list-group-bg-cyan:focus {
    background-color: #00bcd4;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-teal {
    stroke: #009688;
}

.list-group .list-group-bg-teal {
    background-color: #009688;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-teal:hover,
.list-group .list-group-bg-teal:focus {
    background-color: #009688;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-green {
    stroke: #4caf50;
}

.list-group .list-group-bg-green {
    background-color: #4caf50;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-green:hover,
.list-group .list-group-bg-green:focus {
    background-color: #4caf50;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-light-green {
    stroke: #8bc34a;
}

.list-group .list-group-bg-light-green {
    background-color: #8bc34a;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-light-green:hover,
.list-group .list-group-bg-light-green:focus {
    background-color: #8bc34a;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-lime {
    stroke: #cddc39;
}

.list-group .list-group-bg-lime {
    background-color: #cddc39;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-lime:hover,
.list-group .list-group-bg-lime:focus {
    background-color: #cddc39;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-yellow {
    stroke: #ffe821;
}

.list-group .list-group-bg-yellow {
    background-color: #ffe821;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-yellow:hover,
.list-group .list-group-bg-yellow:focus {
    background-color: #ffe821;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-amber {
    stroke: #ffc107;
}

.list-group .list-group-bg-amber {
    background-color: #ffc107;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-amber:hover,
.list-group .list-group-bg-amber:focus {
    background-color: #ffc107;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-orange {
    stroke: #ff9800;
}

.list-group .list-group-bg-orange {
    background-color: #ff9800;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-orange:hover,
.list-group .list-group-bg-orange:focus {
    background-color: #ff9800;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-deep-orange {
    stroke: #ff5722;
}

.list-group .list-group-bg-deep-orange {
    background-color: #ff5722;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-deep-orange:hover,
.list-group .list-group-bg-deep-orange:focus {
    background-color: #ff5722;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-brown {
    stroke: #795548;
}

.list-group .list-group-bg-brown {
    background-color: #795548;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-brown:hover,
.list-group .list-group-bg-brown:focus {
    background-color: #795548;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-grey {
    stroke: #9e9e9e;
}

.list-group .list-group-bg-grey {
    background-color: #9e9e9e;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-grey:hover,
.list-group .list-group-bg-grey:focus {
    background-color: #9e9e9e;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-blue-grey {
    stroke: #607d8b;
}

.list-group .list-group-bg-blue-grey {
    background-color: #607d8b;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-blue-grey:hover,
.list-group .list-group-bg-blue-grey:focus {
    background-color: #607d8b;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-black {
    stroke: #000000;
}

.list-group .list-group-bg-black {
    background-color: #000000;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-black:hover,
.list-group .list-group-bg-black:focus {
    background-color: #000000;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-white {
    stroke: #ffffff;
}

.list-group .list-group-bg-white {
    background-color: #ffffff;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-white:hover,
.list-group .list-group-bg-white:focus {
    background-color: #ffffff;
    color: #fff;
    opacity: 0.8;
}

.list-group .pl-dark-gray {
    stroke: $dark-gray;
}

.list-group .list-group-bg-dark-gray {
    background-color: $dark-gray;
    border: none;
    color: #fff;
}

.list-group .list-group-bg-dark-gray:hover,
.list-group .list-group-bg-dark-gray:focus {
    background-color: $dark-gray;
    color: #fff;
    opacity: 0.8;
}

span.badge {
    min-width: auto;
    float: none;
    font-size: 13px;
}

.list-group span.badge {
    float: right;
}

.btn-success .badge {
    color: #5cb85c;
    background-color: #fff;
}

.btn span {
    line-height: 33pt;
}

.btn-secondary {
    background-color: white !important;
    color: var(--primary);
    border: $button-line-width solid $button-line-color !important;
    box-shadow: none;
}

.btn-small span {
    line-height: 18pt;
}

.btn-small {
    font-size: 12px !important;
    padding: 0 10pt;
}

.btn-circle .material-icons {
    height: 16pt;
}

.btn-circle.btn-small .material-icons {
    height: 24px;
    line-height: 24px;
}

.btn-circle {
    padding: 0;
    height: 36px;
    width: 36px;
}

.btn-circle.btn-small {
    width: 24px;
    height: 24px;
}

.btn-secondary:hover {
    border-color: #00add6 !important;
    color: #00add6 !important;
}

.btn-primary {
    background-color: var(--primary);
    color: #fff;
    border: 1px solid var(--primary);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
    background-color: #00add6;
}

.btn-primary .badge {
    color: #337ab7;
    background-color: #fff;
}

.btn-danger .badge {
    color: #d9534f;
    background-color: #fff;
}

.btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff;
}

.btn:focus {
    outline: none !important;
}

.btn-circle {
    border: none;
    outline: none !important;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.btn-circle i {
    font-size: 18px;
    position: relative;
    /*left: -1px;*/
    line-height: 1;
}

.btn-border-radius-5 {
    border-radius: 5px !important;
}

.btn-border-radius {
    border-radius: 25px !important;
}

.btn-border-rectangle {
    border-radius: 5px !important;
}

.btn-bold {
    font-weight: bold;
}

.btn-link {
    font-weight: bold;
    color: #333;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.btn-link:active,
.btn-link:focus {
    text-decoration: none;
    color: #333;
}

.btn-link:hover {
    text-decoration: none;
    color: #333;
    background-color: #ddd;
}

.btn-circle-lg {
    border: none;
    outline: none !important;
    overflow: hidden;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    border-radius: 50% !important;
}

.btn-circle-lg i {
    font-size: 26px !important;
    position: relative !important;
    left: 0px !important;
    top: 3px;
}

.btn:not(.btn-link):not(.btn-circle):hover {
    outline: none;
}

.btn:not(.btn-link):not(.btn-circle) i {
    font-size: 20px;
    position: relative;
    line-height: 24px;
}

.btn:not(.btn-link):not(.btn-circle) span {
    position: relative;
    top: -4px;
    margin-left: 3px;
}

.btn-warning {
    background-color: $warning !important;
    color: #fff !important;
}

.btn-warning:hover {
    background-color: $warning !important;
    color: #fff !important;
}

.btn-warning:active {
    background-color: $warning !important;
    color: #fff !important;
}

.btn-warning:focus {
    background-color: $warning !important;
    color: #fff !important;
}

.btn-warning:disabled {
    background-color: $warning !important;
    color: #fff !important;
}

.btn-danger {
    background-color: #e04a3e !important;
    color: #fff;
    cursor: pointer;
}

.btn-danger:hover {
    background-color: $danger !important;
    color: #fff !important;
}

.btn-danger:active {
    background-color: $danger !important;
    color: #fff !important;
}

.btn-danger:focus {
    background-color: $danger !important;
    color: #fff !important;
}

.btn-danger:disabled {
    background-color: $danger !important;
    color: #fff !important;
}

.btn-info {
    background-color: #00b0e4 !important;
    color: #fff !important;
}

.btn-info:hover {
    background-color: #00b0e4 !important;
    color: #fff !important;
}

.btn-info:active {
    background-color: #00b0e4 !important;
    color: #fff !important;
}

.btn-info:focus {
    background-color: #00b0e4 !important;
    color: #fff !important;
}

.btn-info:disabled {
    background-color: #00b0e4 !important;
    color: #fff !important;
}

.btn-success {
    background-color: #18ce0f !important;
    color: #fff !important;
}

.btn-success:hover {
    background-color: #18ce0f !important;
    color: #fff !important;
}

.btn-success:active {
    background-color: #18ce0f !important;
    color: #fff !important;
}

.btn-success:focus {
    background-color: #18ce0f !important;
    color: #fff !important;
}

.btn-success:disabled {
    background-color: #18ce0f !important;
    color: #fff !important;
}

.btn-default {
    background-color: $dark-gray !important;
    color: #fff !important;
}

.btn-default:hover {
    background-color: $dark-gray !important;
    color: #fff !important;
}

.btn-default:active {
    background-color: $dark-gray !important;
    color: #fff !important;
}

.btn-default:focus {
    background-color: $dark-gray !important;
    color: #fff !important;
}

.btn-default:disabled {
    background-color: $dark-gray !important;
    color: #fff !important;
}

.bootstrap-select .btn-default {
    background-color: #fff !important;
    color: #333333 !important;
}

.bootstrap-select .btn-default:hover {
    background-color: #fff !important;
    color: #333333 !important;
}

.bootstrap-select .btn-default:active {
    background-color: #fff !important;
    color: #333333 !important;
}

.bootstrap-select .btn-default:focus {
    background-color: #fff !important;
    color: #333333 !important;
}

.bootstrap-select .btn-default:disabled {
    background-color: #fff !important;
    color: #333333 !important;
}

.btn-outline-default {
    background: 0 0 !important;
    color: $dark-gray !important;
    border: 1px solid $dark-gray !important;
}

.btn-outline-default:hover {
    background: $dark-gray !important;
    color: #fff !important;
    border: 1px solid $dark-gray !important;
}

.btn-outline-success {
    background: 0 0 !important;
    color: #18ce0f !important;
    border: 1px solid #18ce0f !important;
}

.btn-outline-success:hover {
    background: #18ce0f !important;
    color: #fff !important;
    border: 1px solid #18ce0f !important;
}

.btn-outline-info {
    background: 0 0 !important;
    color: #00b0e4 !important;
    border: 1px solid #00b0e4 !important;
}

.btn-outline-info:hover {
    background: #00b0e4 !important;
    color: #fff !important;
    border: 1px solid #00b0e4 !important;
}

.btn-outline-warning {
    background: 0 0 !important;
    color: $warning !important;
    border: 1px solid $warning !important;
}

.btn-outline-warning:hover {
    background: $warning !important;
    color: #fff !important;
    border: 1px solid $warning !important;
}

.btn-outline-danger {
    background: 0 0 !important;
    color: $danger !important;
    border: 1px solid $danger !important;
}

.btn-outline-danger:hover {
    background: $danger !important;
    color: #fff !important;
    border: 1px solid $danger !important;
}

.btn-group,
.btn-group-vertical {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}

.btn-group .btn,
.btn-group-vertical .btn {
    box-shadow: none !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.btn-group .btn .caret,
.btn-group-vertical .btn .caret {
    position: relative;
    bottom: 1px;
}

.btn-group .btn-group,
.btn-group-vertical .btn-group {
    box-shadow: none !important;
}

.btn-group .btn + .dropdown-toggle,
.btn-group-vertical .btn + .dropdown-toggle {
    border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.btn-group.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
}

.btn-group.btn-group-justified .btn,
.btn-group.btn-group-justified .btn-group {
    display: table-cell;
    float: none;
    width: 1%;
}

.tblActnBtn {
    background-color: transparent !important;
    box-shadow: none !important;
    color: red;
    padding: 0px 5px;
}

.tblActnBtn:hover {
    background-color: #fff !important;
    box-shadow: none !important;
}

.btn-toggle-dropdown {
    top: 0 !important;
}

.card {
    background: #fff;
    min-height: 50px;
    position: relative;
    margin-bottom: 30px;
    border: none;
}

.card .card-inside-title {
    margin-top: 25px;
    margin-bottom: 15px;
    display: block;
    font-size: 15px;
    color: #000;
}

.card .card-inside-title small {
    color: #999;
    display: block;
    font-size: 11px;
    margin-top: 5px;
}

.card .card-inside-title small a {
    color: #777;
    font-weight: bold;
}

.card .card-inside-title:first-child {
    margin-top: 0;
}

.card .bg-red,
.card .bg-pink,
.card .bg-purple,
.card .bg-deep-purple,
.card .bg-indigo,
.card .bg-blue,
.card .bg-light-blue,
.card .bg-cyan,
.card .bg-teal,
.card .bg-green,
.card .bg-light-green,
.card .bg-lime,
.card .bg-yellow,
.card .bg-amber,
.card .bg-orange,
.card .bg-deep-orange,
.card .bg-brown,
.card .bg-grey,
.card .bg-blue-grey,
.card .bg-black {
    border-bottom: none !important;
    color: #fff !important;
}

.card .bg-red h2,
.card .bg-red small,
.card .bg-red .material-icons,
.card .bg-pink h2,
.card .bg-pink small,
.card .bg-pink .material-icons,
.card .bg-purple h2,
.card .bg-purple small,
.card .bg-purple .material-icons,
.card .bg-deep-purple h2,
.card .bg-deep-purple small,
.card .bg-deep-purple .material-icons,
.card .bg-indigo h2,
.card .bg-indigo small,
.card .bg-indigo .material-icons,
.card .bg-blue h2,
.card .bg-blue small,
.card .bg-blue .material-icons,
.card .bg-light-blue h2,
.card .bg-light-blue small,
.card .bg-light-blue .material-icons,
.card .bg-cyan h2,
.card .bg-cyan small,
.card .bg-cyan .material-icons,
.card .bg-teal h2,
.card .bg-teal small,
.card .bg-teal .material-icons,
.card .bg-green h2,
.card .bg-green small,
.card .bg-green .material-icons,
.card .bg-light-green h2,
.card .bg-light-green small,
.card .bg-light-green .material-icons,
.card .bg-lime h2,
.card .bg-lime small,
.card .bg-lime .material-icons,
.card .bg-yellow h2,
.card .bg-yellow small,
.card .bg-yellow .material-icons,
.card .bg-amber h2,
.card .bg-amber small,
.card .bg-amber .material-icons,
.card .bg-orange h2,
.card .bg-orange small,
.card .bg-orange .material-icons,
.card .bg-deep-orange h2,
.card .bg-deep-orange small,
.card .bg-deep-orange .material-icons,
.card .bg-brown h2,
.card .bg-brown small,
.card .bg-brown .material-icons,
.card .bg-grey h2,
.card .bg-grey small,
.card .bg-grey .material-icons,
.card .bg-blue-grey h2,
.card .bg-blue-grey small,
.card .bg-blue-grey .material-icons,
.card .bg-black h2,
.card .bg-black small,
.card .bg-black .material-icons {
    color: #fff !important;
}

.card .bg-red .badge,
.card .bg-pink .badge,
.card .bg-purple .badge,
.card .bg-deep-purple .badge,
.card .bg-indigo .badge,
.card .bg-blue .badge,
.card .bg-light-blue .badge,
.card .bg-cyan .badge,
.card .bg-teal .badge,
.card .bg-green .badge,
.card .bg-light-green .badge,
.card .bg-lime .badge,
.card .bg-yellow .badge,
.card .bg-amber .badge,
.card .bg-orange .badge,
.card .bg-deep-orange .badge,
.card .bg-brown .badge,
.card .bg-grey .badge,
.card .bg-blue-grey .badge,
.card .bg-black .badge {
    background-color: #fff;
    /* color: #555; */
}

.card .header {
    position: relative;
}

.card .header .header-dropdown {
    position: absolute;
    top: 20px;
    right: 15px;
    list-style: none;
}

.card .header .header-dropdown .dropdown-menu li {
    display: block !important;
}

.card .header .header-dropdown li {
    display: inline-block;
}

.card .header .header-dropdown i {
    font-size: 20px;
    color: #999;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.card .header .header-dropdown i:hover {
    color: #000;
}

.card .header h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}

.card .header h2 small {
    display: block;
    font-size: 12px;
    margin-top: 5px;
    color: #999;
    line-height: 15px;
}

.card .header h2 small a {
    font-weight: bold;
    color: #777;
}

.card .header .col-xs-12 h2 {
    margin-top: 5px;
}

.card {
    font-size: 14px;
}

@media (max-width: 768px) {
    .card {
        font-size: 12px;
        padding: 0;
    }
}

.card .body .col-xs-1,
.card .body .col-sm-1,
.card .body .col-md-1,
.card .body .col-lg-1 {
    margin-bottom: 0;
}

.card .body .col-xs-2,
.card .body .col-sm-2,
.card .body .col-md-2,
.card .body .col-lg-2 {
    margin-bottom: 0;
}

.card .body .col-xs-3,
.card .body .col-sm-3,
.card .body .col-md-3,
.card .body .col-lg-3 {
    margin-bottom: 0;
}

.card .body .col-xs-4,
.card .body .col-sm-4,
.card .body .col-md-4,
.card .body .col-lg-4 {
    margin-bottom: 0;
}

.card .body .col-xs-5,
.card .body .col-sm-5,
.card .body .col-md-5,
.card .body .col-lg-5 {
    margin-bottom: 0;
}

.card .body .col-xs-6,
.card .body .col-sm-6,
.card .body .col-md-6,
.card .body .col-lg-6 {
    margin-bottom: 0;
}

.card .body .col-xs-7,
.card .body .col-sm-7,
.card .body .col-md-7,
.card .body .col-lg-7 {
    margin-bottom: 0;
}

.card .body .col-xs-8,
.card .body .col-sm-8,
.card .body .col-md-8,
.card .body .col-lg-8 {
    margin-bottom: 0;
}

.card .body .col-xs-9,
.card .body .col-sm-9,
.card .body .col-md-9,
.card .body .col-lg-9 {
    margin-bottom: 0;
}

.card .body .col-xs-10,
.card .body .col-sm-10,
.card .body .col-md-10,
.card .body .col-lg-10 {
    margin-bottom: 0;
}

.card .body .col-xs-11,
.card .body .col-sm-11,
.card .body .col-md-11,
.card .body .col-lg-11 {
    margin-bottom: 0;
}

.card .body .col-xs-12,
.card .body .col-sm-12,
.card .body .col-md-12,
.card .body .col-lg-12 {
    margin-bottom: 0;
}

.collapse .well,
.collapse.in .well,
.collapsing .well {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    margin-bottom: 0;
}

.collapse.show,
.collapse.in.show,
.collapsing.show {
    display: block;
}

.sweet-alert {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
}

.sweet-alert p {
    font-size: 14px !important;
}

.sweet-alert .sa-input-error {
    top: 23px !important;
    right: 13px !important;
}

.sweet-alert h2 {
    font-size: 18px !important;
    margin: 0 0 5px 0 !important;
    line-height: 40px;
}

.sweet-alert button {
    font-size: 15px !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
    padding: 5px 20px !important;
}

.media {
    margin-bottom: 25px;
    margin-top: 15px;
}

.media .media-left {
    padding-right: 10px;
}

.media .media-body {
    color: #777;
    font-size: 13px;
}

.media .media-body .media-heading {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.modal .modal-header {
    border: none;
    padding: 30px 25px 5px 25px;
}

.modal .modal-header .modal-title {
    font-weight: 400;
    font-size: 16pt;
}

.modal .modal-content {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.31) !important;
    border: none;
    border-radius: 20px;
    padding: 0;
}

.modal .modal-content .modal-body {
    color: #777;
    padding: 10px 25px;
}

.modal .modal-footer {
    border: none;
}

.right {
    float: right !important;
}

.tooltip {
    font-size: 13px;
}

.tooltip .tooltip-inner {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.popover {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.popover .popover-title {
    font-weight: bold;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background-color: #e9e9e9;
    border-bottom: 1px solid #ddd;
}

.popover .popover-content {
    font-size: 13px;
    color: #777;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.waves-effect.waves-red .waves-ripple {
    background: rgba(244, 67, 54, 0.5);
}

.waves-effect.waves-pink .waves-ripple {
    background: rgba(233, 30, 99, 0.5);
}

.waves-effect.waves-purple .waves-ripple {
    background: rgba(156, 39, 176, 0.5);
}

.waves-effect.waves-deep-purple .waves-ripple {
    background: rgba(103, 58, 183, 0.5);
}

.waves-effect.waves-indigo .waves-ripple {
    background: rgba(63, 81, 181, 0.5);
}

.waves-effect.waves-blue .waves-ripple {
    background: rgba(33, 150, 243, 0.5);
}

.waves-effect.waves-light-blue .waves-ripple {
    background: rgba(3, 169, 244, 0.5);
}

.waves-effect.waves-cyan .waves-ripple {
    background: rgba(0, 188, 212, 0.5);
}

.waves-effect.waves-teal .waves-ripple {
    background: rgba(0, 150, 136, 0.5);
}

.waves-effect.waves-green .waves-ripple {
    background: rgba(76, 175, 80, 0.5);
}

.waves-effect.waves-light-green .waves-ripple {
    background: rgba(139, 195, 74, 0.5);
}

.waves-effect.waves-lime .waves-ripple {
    background: rgba(205, 220, 57, 0.5);
}

.waves-effect.waves-yellow .waves-ripple {
    background: rgba(255, 232, 33, 0.5);
}

.waves-effect.waves-amber .waves-ripple {
    background: rgba(255, 193, 7, 0.5);
}

.waves-effect.waves-orange .waves-ripple {
    background: rgba(255, 152, 0, 0.5);
}

.waves-effect.waves-deep-orange .waves-ripple {
    background: rgba(255, 87, 34, 0.5);
}

.waves-effect.waves-brown .waves-ripple {
    background: rgba(121, 85, 72, 0.5);
}

.waves-effect.waves-grey .waves-ripple {
    background: rgba(158, 158, 158, 0.5);
}

.waves-effect.waves-blue-grey .waves-ripple {
    background: rgba(96, 125, 139, 0.5);
}

.waves-effect.waves-black .waves-ripple {
    background: rgba(0, 0, 0, 0.5);
}

.waves-effect.waves-white .waves-ripple {
    background: rgba(255, 255, 255, 0.5);
}

.waves-effect.waves-dark-gray .waves-ripple {
    background: rgba(136, 136, 136, 0.5);
}

/* Browser support style ===================== */
html.ie10 .sidebar .menu .list li {
    line-height: 30px;
}

html.ie10 .sidebar .menu .list .ml-menu li.active a:not(.menu-toggle).toggled:before {
    top: 6px !important;
    line-height: 20px !important;
}

html.ie10 .sidebar .user-info .info-container {
    top: 15px;
}

html.ie10 .search-bar input[type='text'] {
    padding: 26px 60px 26px 56px;
}

html.ie10 .dropdown-menu ul.menu li a {
    margin-top: -22px;
}

html.ie10 .bs-searchbox .form-control {
    width: 90%;
}

html.ie11 .sidebar .menu .list .ml-menu li.active a:not(.menu-toggle).toggled:before {
    top: 6px !important;
    line-height: 20px !important;
}

html.ie11 .sidebar .user-info .info-container {
    top: 15px;
}

html.ie11 .search-bar input[type='text'] {
    padding: 26px 60px 26px 56px;
}

html.ie11 .dropdown-menu ul.menu li a {
    margin-top: -22px;
}

html.ie11 .bs-searchbox .form-control {
    width: 90%;
}

/* INPUT STYLE OVERIDES */
input[type='text'].af-input,
input[type='password'].af-input,
input[type='number'].af-input,
input[type='email'].af-input,
textarea.af-input {
    color: $dark-gray;
    border-bottom: 1px solid $gray-line-color;
    background: transparent;
    font-size: 14px;
    padding: 0;
    box-sizing: border-box;
}

textarea.af-input {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid $gray-line-color;
    border-radius: 5px;
}

input[type='text'].af-input.url {
    border: 0;
    border-bottom: 1px solid $gray-line-color;
    background: inherit;
}

input[type='text'].af-input.url:focus {
    border: 0;
}

input[type='text'].af-input:disabled,
input[type='password'].af-input:disabled,
input[type='number'].af-input:disabled,
input[type='email'].af-input:disabled,
textarea.af-input:disabled {
    color: rgba(0, 0, 0, 0.82);
    padding-left: 0;
    background: transparent;
}

input[type='text'].af-input.width-limited,
input[type='password'].af-input.width-limited,
input[type='number'].af-input.width-limited,
input[type='email'].af-input.width-limited,
textarea.af-input.width-limited {
    max-width: 120px;
}

input[type='text']:focus.af-input,
input[type='password']:focus.af-input,
input[type='number']:focus.af-input,
input[type='email']:focus.af-input,
textarea:focus.af-input {
    border-bottom: 1px solid var(--primary);
}

/* TABLE STYLE */
table.af-table {
    font-size: inherit;
    table-layout: fixed;
    font-weight: 400;
    color: #495057;
}

table.af-table.align-right th,
table.af-table.align-right td {
    text-align: right;
}

table.af-table tr {
    padding: 0;
    border-bottom: 1px solid #eeeef0;
}

table.af-table tr.tr-head {
    padding: 0;
    border-bottom: 1px solid #dfdce3;
}

table.af-table th {
    padding: 12px 0;
    font-weight: 500;
}

table.af-table td {
    padding: 8px 0;
}

table.af-table td.align-right,
table.af-table th.align-right {
    text-align: right;
}

table.af-table td.align-left,
table.af-table th.align-left {
    text-align: left;
}

table.af-table.table-hover tbody tr:hover {
    background-color: #f1f4fb;
}

/* SELECT STYLE OVERRIDES */

select.af-select {
    border-bottom: 1px solid $gray-line-color !important;
    font-size: 12px !important;
    box-sizing: border-box !important;
    font-weight: 300;
    cursor: pointer;
    text-indent: 6px;
    height: 45px;
    display: block;
}

select.af-select:disabled,
select.af-select[readonly] {
    opacity: 0.4;
    pointer-events: none;
}

/* AF ROW */

.af-row-container {
    padding: 20px;
    border-bottom: 1px solid #eeeef0;
}

.af-row-container.plain {
    padding: 20px 0;
}

.af-row-container:last-child {
    border-bottom: none;
}

.af-row-container .col-sm-6 {
    margin: 0 !important;
}

.af-row-container h3 {
    margin: 0 !important;
    line-height: 16px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
}

.af-row-container h4 {
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    margin: 0 !important;
    display: inline-block;
}

.af-row-container h5 {
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
}

.af-row-container .bold {
    font-weight: 500;
}

.af-row-container i.material-icons {
    line-height: 16px;
    height: 16px;
}

/* POP UP STYLE */

.swal2-popup {
    border-radius: 20px !important;
}

.swal2-popup .swal2-header .swal2-icon {
    display: flex;
    height: 30pt;
    width: 30pt;
    font-size: 6pt;
    padding: 0;
    margin: 5px 0 10px 0;
}

/* AF OTHER */
.horizontal-line {
    height: 1px;
    background: #eeeef0;
}

p {
    font-size: inherit;
}

.faint {
    opacity: 0.7;
    margin-left: 3px;
}

.flex-container {
    display: flex;
}

.flex-container.row-reverse {
    flex-direction: row-reverse;
}

.flex-container.flex-end {
    flex-direction: row;
    justify-content: flex-end;
}

.flex1 {
    flex: 1;
}

.max-width-30-percents {
    max-width: 30%;
}

.inline-block {
    display: inline-block;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

/* HACK when sorting schedule by area make stages smaller text */

.sorted-by-area .stage-name {
    color: #343a40 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-bottom: 8pt !important;
}

.font-weight-normal {
    font-weight: 400;
}

.strong {
    font-weight: 500;
}

.fit-content-width {
    width: fit-content;
    width: -moz-fit-content;
}

.min-w-60 {
    max-width: 60px;
}

.w-200 {
    width: 200px;
}

.min-w-250 {
    min-width: 250px;
    width: 250px;
}

.w-250 {
    width: 250px;
}

.min-w-300 {
    width: 300px;
    min-width: 300px;
}
